import { Text } from '@consta/uikit/Text';
import { FC } from 'react';

interface Props {
    title: string,
    description: string,
    style?: React.CSSProperties
}

export const EmptyDataPlaceholder: FC<Props> = ({ title, description, style }) => {
    return <div style={ style ?? { paddingTop: 20 }}>
        <Text view="primary" align="center">{title}</Text>
        <Text view="secondary" align="center">{description}</Text>
    </div>
}