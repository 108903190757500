import { FC, Fragment } from "react"
import { Space } from "../../../components/Space"
import { format, isAfter, isBefore, parseISO } from "date-fns"
import { Text } from '@consta/uikit/Text';
import { DriverPassengerRequest, DriverPassengerRequestState, SlotType } from "../home/types";
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { IconTarget } from '@consta/icons/IconTarget';
import { Divider } from "../../../components/Divider";


interface Props {
    request: DriverPassengerRequest
}

interface StepItem {
    id: string
    title: string
    date: Date
    endDate: Date | undefined
    completed: boolean
    notPerformed: boolean
    inProgress: boolean
}

export const PassengerRequestDetails: FC<Props> = ({ request }) => {
    const steps2: StepItem[] = []

    request.pickUpLocation.planSlots.forEach(element => steps2.push({
        id: element.id,
        date: parseISO(element.beginDate),
        endDate: factSlotEndDate({ slotId: element.id }),
        completed: element.state == DriverPassengerRequestState.COMPLETED,
        inProgress: element.state == DriverPassengerRequestState.IN_PROGRESS,
        notPerformed: element.state == DriverPassengerRequestState.NOT_PERFORMED,
        title: element.type == SlotType.ResourceOperationStepSlot ? `Посадка: ${element.titleOf}` : `${element.titleOf} -> ${element.titleTo}`
    }))

    request.dropLocations.flatMap(element => element.planSlots).forEach(element => steps2.push({
        id: element.id,
        date: parseISO(element.beginDate),
        endDate: factSlotEndDate({ slotId: element.id }),
        completed: element.state == DriverPassengerRequestState.COMPLETED,
        inProgress: element.state == DriverPassengerRequestState.IN_PROGRESS,
        notPerformed: element.state == DriverPassengerRequestState.NOT_PERFORMED,
        title: element.type == SlotType.ResourceOperationStepSlot ? `Высадка: ${element.titleOf}` : `${element.titleOf} -> ${element.titleTo}`
    }))

    steps2.sort((a, b) => a.date.getTime() - b.date.getTime())

    function factSlotEndDate({ slotId }: { slotId: string }): Date | undefined {
        const pickuUp = request.pickUpLocation.factSlots.find(element => element.id == slotId)?.endDate
        if (pickuUp) {
            return parseISO(pickuUp)
        }

        const drop = request.dropLocations.flatMap(element => element.factSlots).find(element => element.id == slotId)?.endDate
        if (drop) {
            return parseISO(drop)
        }
        return undefined
    }

    return <Space
        size="s"
        direction="vertical"
        style={{
            padding: 'var(--space-l)',
        }}
    >
        <Text view="primary" weight="medium" size="m">{request.name}</Text>
        <Text view="secondary" weight="medium" size="m">{request.phoneNumber}</Text>
        <Divider />

        <>
            {steps2.map((item) => <Fragment key={item.id}>
                <Space>
                    <div style={{ width: 10 }} />

                    {(!item.completed && !item.inProgress && !item.notPerformed) ? <IconTarget view="ghost"/> : <></>}

                    {(item.notPerformed) ? <IconTarget view="alert"/> : <></>}

                    {(item.completed) ? <IconTarget view="success"/> : <></>}

                    {item.inProgress ? <ProgressSpin /> : <></>}

                    <div>
                        <Text view='primary' weight="semibold">{item.title}</Text>
                        <Space>
                            <Text view='secondary'>{format(item.date, "HH:mm")}</Text>
                            {item.endDate && isBefore(item.endDate, item.date)  ? <Text view='success' weight="semibold">{format(item.endDate, "HH:mm")}</Text> : <></>}
                            {item.endDate && isAfter(item.endDate, item.date)  ? <Text view='alert' weight="semibold">{format(item.endDate, "HH:mm")}</Text> : <></>}
                        </Space>
                    </div>
                </Space>
            </Fragment>)}
        </>
    </Space>
}