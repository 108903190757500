import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { TextField } from "@consta/uikit/TextField";
import { SelectWorkPlaceDialog } from "./components/SelectWorkPlaceDialog";


export const SelectWorkPlace = ({
    label,
    disabled,
    level = 3,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    ...fieldProps
}: any) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const context = useFormContext()

    const renderSelectWorkPlace = ({ field }: any) => {
        const { onChange, onBlur, value, ref } = field
        return (
            <div>
                <TextField
                    disabled={disabled}
                    onClick={() => {
                        setIsDialogOpen(true);
                        onChange({
                            oilField: undefined,
                            cluster: undefined,
                            well: undefined,
                        })
                    }}
                    style={{ width: '100%' }}
                    {...fieldProps}
                    onBlur={onBlur}
                    ref={ref}
                    placeholder="Выберите место"
                    required={rules?.required}
                    label={label ?? 'Место'}
                    type="textarea"
                    readOnly
                    value={value?.oilField && value?.cluster ? `${value.oilField.title} / ${value.cluster.title} ${value?.well ? '/' : ''} ${value?.well ? value?.well!.title : ''}` : ''}
                />

                <SelectWorkPlaceDialog
                    level={level}
                    isOpen={isDialogOpen}
                    onClose={() => {
                        setIsDialogOpen(false)
                    }}
                    onSelect={(workPlace) => {
                        setIsDialogOpen(false)
                        onChange({
                            oilField: workPlace.oilField,
                            cluster: workPlace.cluster,
                            well: workPlace.well,
                        })
                    }}
                />
            </div>
        );
    };

    return <Controller
        name={name}
        rules={{
            validate: (field) => {
                if (!!!rules.required) {
                    return true
                } else {
                    return field?.oilField != undefined && field?.cluster != undefined;
                }
            }
        }}
        defaultValue={defaultValue}
        control={control || context.control}
        shouldUnregister={shouldUnregister}
        render={renderSelectWorkPlace}
    />
}