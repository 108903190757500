import { FC } from "react"
import { Text } from '@consta/uikit/Text';
import { IconForward } from '@consta/icons/IconForward';
import { Space } from "./Space";

interface Props {
    titleOf: string | undefined | null
    titleTo: string | undefined | null
}

export const EquipmentRoute: FC<Props> = ({ titleOf, titleTo }) => {
    if (!titleOf && !titleTo) {
        return <></>
    } else {
        return <Space size="xs">
            <Text view="primary" weight="semibold" size="l">{titleOf}</Text>
            <IconForward size="m" view="secondary" style={{ paddingLeft: 6, paddingRight: 6 }} />
            <Text view="primary" weight="semibold" size="l">{titleTo}</Text>
        </Space>
    }
}