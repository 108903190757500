import { Badge } from '@consta/uikit/Badge';
import { FC } from 'react';
import { MasterRequestState } from '../types';

interface Props {
    state: MasterRequestState
}

export const RequestStateLabel: FC<Props> = ({ state }) => {
    const labels: Record<MasterRequestState, string> = {
        [MasterRequestState.PLANNED]: "Запланирован",
        [MasterRequestState.NOT_PLANNED]: "Создана",
        [MasterRequestState.DELAYED]: "Задерживается",
        [MasterRequestState.IN_PROGRESS]: "В работе",
        [MasterRequestState.LOCKED]: "Техн. закреплена",
        [MasterRequestState.LOCKED_AUTO]: "Техн. закреплена",
        [MasterRequestState.CONFIRMED]: "Утверждено",
        [MasterRequestState.NOT_PERFORMED]: "Не выполнено",
        [MasterRequestState.DEFICIT]: "Дефицит",
        [MasterRequestState.CREATED]: "Создана",
        [MasterRequestState.COMPLETED]: "Завершена",
        [MasterRequestState.PART_COMPLETED]: "Завершена частично",
     
    }

    const status: Record<MasterRequestState, "normal" | "error" | "success" | "warning" | "system" | undefined> = {
        [MasterRequestState.PLANNED]: "system",
        [MasterRequestState.NOT_PLANNED]: "system",
        [MasterRequestState.DELAYED]: "warning",
        [MasterRequestState.IN_PROGRESS]: "normal",
        [MasterRequestState.LOCKED]: "warning",
        [MasterRequestState.LOCKED_AUTO]: "warning",
        [MasterRequestState.CONFIRMED]: "success",
        [MasterRequestState.COMPLETED]: "success",
        [MasterRequestState.PART_COMPLETED]: "success",
        [MasterRequestState.NOT_PERFORMED]: "error",
        [MasterRequestState.DEFICIT]: "error",
        [MasterRequestState.CREATED]: "system",
    }

    return <Badge status={status[state]} label={labels[state]} size='s' style={{width: 'fit-content'}}/>
}