import { Badge } from '@consta/uikit/Badge';
import { FC } from 'react';
import { SlotState } from '../../../types';

interface Props {
    state: SlotState
}

export const SlotStateLabel: FC<Props> = ({ state }) => {
    const labels: Record<SlotState, string> = {
        [SlotState.PLANNED]: "Запланирован",
        [SlotState.DELAYED]: "Задержка",
        [SlotState.IN_PROGRESS]: "В работе",
        [SlotState.LOCKED_AUTO]: "Закреплен",
        [SlotState.LOCKED_MANUAL]: "Закреплен",
        [SlotState.COMPLETED]: "Завершен",
    }

    const status: Record<SlotState, "normal" | "error" | "success" | "warning" | "system" | undefined> = {
        [SlotState.PLANNED]: "system",
        [SlotState.DELAYED]: "warning",
        [SlotState.IN_PROGRESS]: "normal",
        [SlotState.LOCKED_AUTO]: "warning",
        [SlotState.LOCKED_MANUAL]: "warning",
        [SlotState.COMPLETED]: "success",
    }

    return <Badge status={status[state]} label={labels[state]} />
}