import { FC } from "react"
import { DriverNotification } from "./types"
import { Space } from "../../../components/Space";
import { Button } from "@consta/uikit/Button";
import { IconClose } from '@consta/icons/IconClose';
import { useNoticeStatusMutation } from "./services";


interface Props {
    notification: DriverNotification,
    onClose: () => void,
}

export const DriverNotificationView: FC<Props> = ({ notification, onClose }) => {
    const [sendRequest] = useNoticeStatusMutation()

    return <Space direction="horizontal">
        <span>
            {notification.text}
        </span>

        <Button
            iconRight={IconClose}
            onlyIcon
            view="ghost"
            size="xs"
            onClick={
                () => {
                    onClose()
                    sendRequest({ notificationId: notification.notificationId });
                }
            }
        />
    </Space>
}