import { useEffect } from "react"
import { Divider } from "../../../../../components/Divider"
import SliderButton from "../../../../../components/SliderButton"
import { Space } from "../../../../../components/Space"
import { useExecutionStartedMutation, useMovementStartedMutation } from "../services"
import toast from "react-hot-toast"
import { useGetScheduleDataQuery } from "../../services"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../store"


export const DriverPassengerStateStartMovement = () => {
    const currentSlot = useSelector((state: RootState) => state.driverPassenger.currentSlot!)
    const currentRequest = useSelector((state: RootState) => state.driverPassenger.currentRequest!)

    const [movementStarted, { isLoading, isError, isSuccess }] = useMovementStartedMutation()
    const [executionStarted] = useExecutionStartedMutation()
    const { refetch: refetchSchedule, isFetching } = useGetScheduleDataQuery(undefined, { skip: !isSuccess })

    useEffect(() => {
        if (isError) {
            toast.error('Произошла ошибка. Не удалось начать поездку')
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            executionStarted({ requestId: currentRequest.id })
            refetchSchedule()
        }
    }, [isSuccess])

    return <Space direction="vertical">

        <div style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
        }}>
            <SliderButton
                loading={isLoading || isFetching}
                text='Старт'
                color='#97b2c4'
                onSuccess={() => movementStarted({ slotId: currentSlot.currentSlot.id })}
            />
        </div>

        <Divider />

    </Space>
}