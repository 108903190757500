import { useNavigate } from "react-router"
import { AppBar } from "../../../components/AppBar"
import { Space } from "../../../components/Space"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { Select } from "@consta/uikit/Select"
import { useGetDisengageTypesQuery } from "../../../mdm/services"
import { TextField } from "@consta/uikit/TextField"
import { Button } from "@consta/uikit/Button"
import { useEffect } from "react"
import { useGetScheduleDataQuery } from "../services"
import { useCreateOutOfServiceMutation } from "./services"
import toast, { Toaster } from 'react-hot-toast';

type ReasonItem = {
    label: string;
    id: string;
}

type FormData = {
    reason: ReasonItem
    comment: string
}

export const DriverOfflineRequest = () => {
    const navigate = useNavigate()

    const { data } = useGetDisengageTypesQuery()
    const [sendRequest, { isLoading, isError, isSuccess }] = useCreateOutOfServiceMutation()
    const { refetch: refetchWorkSchedule } = useGetScheduleDataQuery(undefined, { skip: !isSuccess })


    const onSubmit: SubmitHandler<FormData> = async (data) => {
        await sendRequest({
            comment: data.comment,
            reason: data.reason!.id
        })
    }

    useEffect(() => {
        if (isError) {
            toast.error('Произошла ошибка. Не удалось снять с линии технику')
        }
        if (isSuccess) {
            navigate(-1)
        }
    }, [isError, isSuccess])

    const {
        handleSubmit,
        control,
        watch,
        formState: { isDirty, isValid },
    } = useForm<FormData>({})

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div>
            <AppBar
                style={{ zIndex: 2 }}
                title="Сойти с линии"
                onBackPress={() => navigate(-1)}
            />
            <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}
            >
                <Controller
                    name="reason"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Select
                        style={{ zIndex: 2 }}
                        {...field}
                        placeholder="Выберите причину"
                        items={data ? Object.keys(data).map((key): ReasonItem => (
                            {
                                label: data[key],
                                id: key,
                            }
                        )) : []}
                        onChange={(value) => field.onChange(value.value)}
                    />}
                />

                <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => <TextField
                        {...field}
                        onChange={(value) => field.onChange(value.value)}
                        type="textarea"
                        placeholder="Комментарий"
                        rows={5}
                    />}
                />
            </Space>

            <Button
                form="round"
                label="Отправить"
                disabled={!isDirty || !isValid}
                loading={isLoading}
                style={{
                    backgroundColor: (!isDirty || !isValid) ? '#0078D280' : '#0078D2',
                    position: "absolute",
                    bottom: 'var(--space-l)',
                    right: 'var(--space-l)',
                    left: 'var(--space-l)',
                }}
                type="submit"
            />
        </div>
    </form>
}