import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { EquipmentPosition, FeatureCollection, GeometryPoint, GeometryPoints } from "./types"
import { LngLatLike } from "mapbox-gl"


export interface MapState {
    equipmentPositions: EquipmentPosition[]
    selectedEquipmentPosition: EquipmentPosition | null
    equipmentRoute: GeometryPoints | undefined
    centerPoint: LngLatLike | undefined
    lastUpdateDate: number | undefined
    manualPoints: FeatureCollection | undefined
}

const initialState: MapState = {
    equipmentPositions: [],
    selectedEquipmentPosition: null,
    equipmentRoute: undefined,
    centerPoint: undefined,
    lastUpdateDate: undefined,
    manualPoints: undefined,
}

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        updateEquipmentPositions: (state, action: PayloadAction<EquipmentPosition[]>) => {
            state.equipmentPositions = action.payload
        },
        updateSelectedEquipmentPosition: (state, action: PayloadAction<EquipmentPosition | null>) => {
            state.selectedEquipmentPosition = action.payload
        },
        updateEquipmentRoute: (state, action: PayloadAction<GeometryPoints | undefined>) => {
            state.equipmentRoute = action.payload
        },
        updateCenterPoint: (state, action: PayloadAction<LngLatLike | undefined>) => {
            state.centerPoint = action.payload
        },
        updateLastUpdateDate: (state, action: PayloadAction<number>) => {
            state.lastUpdateDate = action.payload
        },
        updateManualPoints: (state, action: PayloadAction<FeatureCollection | undefined>) => {
            state.manualPoints = action.payload
        },
    },
})

export const {
    updateEquipmentPositions,
    updateSelectedEquipmentPosition,
    updateEquipmentRoute,
    updateCenterPoint,
    updateLastUpdateDate,
    updateManualPoints,
} = mapSlice.actions

export default mapSlice.reducer