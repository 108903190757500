import { Badge } from "@consta/uikit/Badge"
import { FC } from "react"
import styles from './styles.module.css';


interface Props {
    regNumber: string | undefined
}

export const RegisterPlate: FC<Props> = ({ regNumber }) => {
    return <Badge
        className={styles.registerPlate}
        view="filled"
        size="l"
        // status="system"
        label={regNumber}
    />
}