import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UserState {
    username: string | undefined
}

const initialState: UserState = {
    username: undefined,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUsername: (state, action: PayloadAction<string | undefined>) => {
            state.username = action.payload
        },
    },
})

export const {
    updateUsername,
} = userSlice.actions

export default userSlice.reducer