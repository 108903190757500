import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';
import { ShiftDescrption } from '../utils/workShifts';


export function ShiftLabel({ description, count }: { description: ShiftDescrption, count: number }) {
    return <div style={{paddingLeft: 'var(--space-l)',}}>
        <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
            <Text view="primary" weight="medium">{description.title}</Text>
            {count >0 && <Badge form="round" label={count.toString()} status="system" style={{ marginLeft: 8 }} />}
        </div>
        <Text view="secondary" style={{ marginTop: 6 }}>{description.period}</Text>
    </div>
}