import { FC, useState } from "react"
import { Text } from "@consta/uikit/Text"
import { SkeletonBrick } from "@consta/uikit/Skeleton"
import { MdmEquipmentItem } from "../../../../mdm/types"
import { useGetEquipmentItemsQuery } from "../../../../mdm/services"
import { Space } from "../../../../components/Space"
import { ErrorDataPlaceholder } from "../../../../components/ErrorDataPlaceholder"
import { Divider } from "../../../../components/Divider"
import { IconSearchStroked } from '@consta/icons/IconSearchStroked';
import { TextField } from '@consta/uikit/TextField';


interface Props {
    onSelect: (item: MdmEquipmentItem) => void
}

export const EquipmetItemList: FC<Props> = ({ onSelect }) => {
    const { data, isLoading, isError } = useGetEquipmentItemsQuery()

    const [value, setValue] = useState<string | null>(null);
    const handleChange = ({ value }: { value: string | null }) => {
        setValue(value)
    };

    function filteredData() {
        if (data) {
            return data.filter((equipment) => {
                if (!!!value || value === '') {
                    return equipment;
                } else {
                    return equipment.registrationPlateNumber.toLowerCase().includes(value);
                }
            })
        } else {
            return []
        }
    }

    return <div style={{paddingBottom: '20'}}>
        {isLoading && !data && <Space
            size="xs"
            direction="vertical"
            style={{
                paddingLeft: 'var(--space-s)',
                paddingRight: 'var(--space-s)',
            }}>
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
        </Space>}

        {isError && <ErrorDataPlaceholder title={"Ошибка"} description={"Не удалось загрузить данные"}/>}

        {(data && !isLoading) && <Space
            direction="vertical"

        >
            <div style={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                paddingTop: 10,
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            >
                <TextField
                    width="full"
                    onChange={handleChange}
                    value={value}
                    type="text"
                    placeholder="Поиск"
                    withClearButton
                    leftSide={IconSearchStroked}
                />
            </div>
            <div style={{
                marginBottom: 'var(--space-m)',
                paddingTop: 60,
                paddingBottom: 20,
            }}>
                {filteredData().map((item, index) => {
                    return <div
                        style={{
                            paddingLeft: 'var(--space-l)',
                            paddingRight: 'var(--space-l)',
                        }}
                        key={item.id}
                        onClick={() => { onSelect(item) }}
                    >
                        <Text style={{ paddingTop: 'var(--space-s)', paddingBottom: 'var(--space-s)' }} >{item.registrationPlateNumber}</Text>
                        {index !== data.length - 1 && <Divider />}
                    </div>
                })
                }
            </div>

        </Space>}
    </div>
}