import { createBrowserRouter } from "react-router-dom";
import { Root } from "./pages/root";
import { Paths } from "./path";
import { ErrorBoundaryV2 } from "./components/ErrorBoundary";
import { MasterJobRequest } from "./pages/master/requests/MasterJobRequest";
import { DriverOfflineRequest } from "./pages/driver/DriverOfflineRequest";
import { MechanicBindDevice } from "./pages/mechanic/BindDevice";
import { MechanicRequests } from "./pages/mechanic/requests";
import { MasterRouteRequest } from "./pages/master/requests/MasterRouteRequest";
import { MasterTransportationRequest } from "./pages/master/requests/MasterTransportationRequest";
import { MasterMaterialRequest } from "./pages/master/requests/MasterMaterialRequest";
import { MasterTeamTransferRequest } from "./pages/master/requests/MasterTeamTransferRequest";
import { MasterPassengerRequest } from "./pages/master/requests/MasterPassengerRequest";


export const Router = createBrowserRouter([
  {
    path: Paths.root,
    element: <Root />,
    errorElement: <ErrorBoundaryV2 />,
  },
  {
    path: Paths.mechanicRequests,
    element: <MechanicRequests />,
    errorElement: <ErrorBoundaryV2 />
  },
  {
    path: Paths.driverOfflineRequest,
    element: <DriverOfflineRequest />,
    errorElement: <ErrorBoundaryV2 />
  },
  {
    path: Paths.masterJobRequest,
    errorElement: <ErrorBoundaryV2 />,
    children: [
      {
        path: Paths.masterJobRequest,
        element: <MasterJobRequest />,
      },
      {
        path: `${Paths.masterJobRequest}/edit/:requestId`,
        element: <MasterJobRequest />,
      },
      {
        path: `${Paths.masterJobRequest}/copy/:requestId`,
        element: <MasterJobRequest />,
      },
    ]
  },
  {
    path: Paths.masterRouteRequest,
    errorElement: <ErrorBoundaryV2 />,
    children: [
      {
        path: Paths.masterRouteRequest,
        element: <MasterRouteRequest />,
      },
      {
        path: `${Paths.masterRouteRequest}/edit/:requestId`,
        element: <MasterRouteRequest />,
      },
      {
        path: `${Paths.masterRouteRequest}/copy/:requestId`,
        element: <MasterRouteRequest />,
      },
    ]
  },
  {
    path: Paths.masterTransportationRequest,
    children: [
      {
        path: Paths.masterTransportationRequest,
        element: <MasterTransportationRequest />,
      },
      {
        path: `${Paths.masterTransportationRequest}/edit/:requestId`,
        element: <MasterTransportationRequest />,
      },
      {
        path: `${Paths.masterTransportationRequest}/copy/:requestId`,
        element: <MasterTransportationRequest />,
      },
    ]
  },
  {
    path: Paths.masterMaterialRequest,
    errorElement: <ErrorBoundaryV2 />,
    children: [
      {
        path: Paths.masterMaterialRequest,
        element: <MasterMaterialRequest />,
      },
      {
        path: `${Paths.masterMaterialRequest}/edit/:requestId`,
        element: <MasterMaterialRequest />,
      },
      {
        path: `${Paths.masterMaterialRequest}/copy/:requestId`,
        element: <MasterMaterialRequest />,
      },
    ],
  },
  {
    path: Paths.masterTeamTransferRequest,
    errorElement: <ErrorBoundaryV2 />,
    children: [
      {
        path: Paths.masterTeamTransferRequest,
        element: <MasterTeamTransferRequest />,
      },
      {
        path: `${Paths.masterTeamTransferRequest}/edit/:requestId`,
        element: <MasterTeamTransferRequest />,
      },
      {
        path: `${Paths.masterTeamTransferRequest}/copy/:requestId`,
        element: <MasterTeamTransferRequest />,
      },
    ]
  },
  {
    path: Paths.masterPassTransferRequest,
    errorElement: <ErrorBoundaryV2 />,
    children: [
      {
        path: Paths.masterPassTransferRequest,
        element: <MasterPassengerRequest />,
      },
      {
        path: `${Paths.masterPassTransferRequest}/edit/:requestId`,
        element: <MasterPassengerRequest />,
      },
      {
        path: `${Paths.masterPassTransferRequest}/copy/:requestId`,
        element: <MasterPassengerRequest />,
      },
    ]
  },
  {
    path: Paths.mechanicBindDevice,
    element: <MechanicBindDevice />,
    errorElement: <ErrorBoundaryV2 />
  },
])