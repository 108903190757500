import { EquipmentState } from "../../../components/EquipmentStateLabel/types"
import { GeometryPoint, GeometryPoints } from "../../../components/Map/types"
import { MdmWorkShift } from "../../../mdm/types"

export interface DriverPassengerScheduleData {
    schedule: ScheduleData
    shift: MdmWorkShift
    slot: DriverPassengerSlot,
}

export interface ScheduleData {
    requests: DriverPassengerRequest[]
    newMessage: boolean
}

export interface DriverPassengerRequest {
    id: string
    name: string
    reqNum: number
    regDt: Date
    type: string
    state: DriverPassengerRequestState
    beginDate: string
    endDate: string
    hasFactSlots: boolean
    teamId: string
    teamTitle: string
    contractorTitle: string
    companyTitle: string
    stepTitle: string
    changedBy: string
    createdBy: string
    changeDate: string
    createdSource: string
    changedSource: string
    requestPurpose: string
    phoneNumber: string
    fixOrder: boolean
    numberOfPassengers: number
    equipmentTypeId: string
    pickUpLocation: Location
    dropLocations: Location[]
}

interface Location {
    destinationPoint: DestinationPoint
    poiId: string
    deleted: boolean
    id: string
    wellPadGroupTitle: string
    wellPadTitle: string
    planSlots: SlotDetails[]
    factSlots: SlotDetails[]
    poiTypes: string[]
}

interface DestinationPoint {
    lat: number
    lng: number
}

interface SlotDetails {
    id: string
    beginDate: string
    endDate: string
    resourceId: string
    activityId: string
    roleId: string
    version: number
    type: SlotType
    state: DriverPassengerRequestState
    pointOf: GeometryPoint
    pointTo: GeometryPoint
    poiIdOf: string
    poiIdTo: string
    poiTypeOf: string
    poiTypeTo: string
    titleOf: string
    titleTo: string
    hasMaterials: boolean
    hasDisposalMaterials: boolean
    assignment: boolean
    distance?: number
}

export enum DriverPassengerRequestState {
    PLANNED = "PLANNED",
    DELAYED = "DELAYED",
    IN_PROGRESS = "IN_PROGRESS",
    CONFIRMED = "CONFIRMED",
    NOT_PERFORMED = "NOT_PERFORMED",
    LOCKED_AUTO = "LOCKED_AUTO",
    LOCKED_MANUAL = "LOCKED_MANUAL",
    COMPLETED = "COMPLETED",
    LOCKED = "LOCKED",
    DEFICIT = "DEFICIT",
    PART_COMPLETED = "PART_COMPLETED",
}

export interface DriverPassengerSlot {
    resourceState: EquipmentState
    registeredAt: string
    shiftStarted: boolean
    registrationPlateNumber: string
    requestId: string
    currentSlot: {
        id: string
        geometry: GeometryPoints
        type: SlotType
        state: DriverPassengerRequestState
    }
}

export enum SlotType {
    ResourceReturnToBaseLocationSlot = "ResourceReturnToBaseLocationSlot",
    ResourceSupplyBaseLoadingSlot = "ResourceSupplyBaseLoadingSlot",
    ResourceTransportationSlot = "ResourceTransportationSlot",
    ResourceOperationStepSlot = "ResourceOperationStepSlot",
}