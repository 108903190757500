import { Text } from '@consta/uikit/Text';
import { IconCancel } from '@consta/icons/IconCancel';
import { FC } from 'react';

interface Props {
    title: string,
    description: string,
    style?: React.CSSProperties
}

export const ErrorDataPlaceholder: FC<Props> = ({ title, description, style }) => {
    return <div style={{ paddingTop: style?.paddingTop ?? 20 }}>
        <IconCancel style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} />
        <Text view="primary" align="center">{title}</Text>
        <Text view="secondary" align="center">{description}</Text>
    </div>
}