import type { FC, ReactElement } from 'react';

import cn from 'classnames';
import styles from './styles.module.css';
import React from 'react';

interface Props {
  children: ReactElement[],
  direction?: 'vertical' | 'horizontal';
  size?: '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
  align?: 'start' | 'center' | 'end';
  wrap?: boolean;
  spaceBetween?: boolean;
  className?: string;
  style?: React.CSSProperties
}

export const Space: FC<Props> = ({
  children,
  size = 'm',
  align = 'center',
  direction = 'horizontal',
  wrap = false,
  spaceBetween = false,
  className,
  style,
}) => {
  return (
    <div
      style={style}
      className={cn(
        styles.container,
        className,
        styles[`size-${size}`],
        styles[`direction-${direction}`],
        styles[`align-${align}`],
        {
          [styles.wrap]: wrap,
          [styles.spaceBetween]: spaceBetween,
        },
      )}
    >
      {children}
    </div>
  );
};
