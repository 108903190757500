import { useSelector } from "react-redux";
import { Divider } from "../../../components/Divider";
import { Space } from "../../../components/Space"
import { IconAlert } from '@consta/icons/IconAlert';
import { Text } from '@consta/uikit/Text';
import { RootState } from "../../../store";


export const DriverErrorState = () => {
    const equipmentState = useSelector((state: RootState) => state.driver.equipmentState)
    const currentSlot = useSelector((state: RootState) => state.driver.currentSlot)

    return <Space
        direction="vertical"
        size="s"
        style={{
            paddingTop: 10,
            alignItems: "center"
        }}>
        <IconAlert view="alert" />
        <Text
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
            }}
            view="critical">Не удалось определить текущее состояние: {equipmentState} / {currentSlot?.type}
        </Text>
        <Divider />
    </Space>
}