import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconRevert } from '@consta/icons/IconRevert';
import { FC, useState } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { Space } from '../../../../components/Space';
import { IconSearchStroked } from '@consta/icons/IconSearchStroked';
import { BottomSheetDefaultHeader } from '../../../../components/BottomSheetDefaultHeader';

interface Props {
    refetch: () => void
    search: (value: string | null) => void
}

export const EquipmentsHeader: FC<Props> = ({ refetch, search }) => {
    const [value, setValue] = useState<string | null>(null);
    const handleChange = ({ value }: { value: string | null }) => {
        setValue(value)
        search(value);
    };

    return <Space
        direction='vertical'
        style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
        }} >
        <BottomSheetDefaultHeader />
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: '100%'
        }}>

            <Text
                view="primary"
                weight="bold"
                size='2xl'
            >Техника</Text>
            <Button
                label="Обновить"
                view="ghost"
                size='s'
                iconLeft={IconRevert}
                onlyIcon
                onClick={refetch}
            />
        </div>
        <TextField
            style={{ width: '100%' }}
            onChange={handleChange}
            value={value}
            type="text"
            placeholder="Введите гос. номер"
            withClearButton
            leftSide={IconSearchStroked}
        />
        <div style={{ height: 4}} />
    </Space>
}