import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { TextField } from "@consta/uikit/TextField";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { BottomSheetTitleHeader } from '../../../../../components/BottomSheetTitleHeader';
import React from 'react';
import { ServiceTypesList } from './components/ServiceTypesList';
import { MdmServiceBlock, MdmServiceOperation, MdmServiceType } from '../../../../../mdm/types';
import { ServiceOperationsList } from './components/ServiceOperationsList';
import { ServiceBlocksList } from './components/ServiceBlocksList';
import { TaskJobValue } from "../../MasterJobRequest/types";
import { Space } from "../../../../../components/Space";
import { Button } from "@consta/uikit/Button";
import { Divider } from "../../../../../components/Divider";
import { Switch } from '@consta/uikit/Switch';


export const SelectJobTask = ({
  disabled,
  name,
  rules,
  defaultValue,
  control,
  shouldUnregister,
  style,
  ...fieldProps
}: any) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const context = useFormContext()
  const refBottomSheet = React.useRef<SheetRef>()

  const [serviceType, setServiceType] = useState<MdmServiceType | undefined>(undefined)
  const [serviceOperation, setServiceOperation] = useState<MdmServiceOperation | undefined>(undefined)
  const [serviceBlock, setServiceBlock] = useState<MdmServiceBlock | undefined>(undefined)
  const [useTextMode, setUseTextMode] = useState<boolean>(false)

  const renderSelectJobTask = ({ field }: any) => {
    const { onChange, onBlur, value, ref } = field

    return (
      <div>
        <Space direction="vertical">
          <TextField
            disabled={disabled}
            onClick={useTextMode ? undefined : () => {
              setServiceType(undefined)
              setServiceOperation(undefined)
              setServiceBlock(undefined)
              setIsDialogOpen(true)
            }}
            style={{ width: '100%' }}
            {...fieldProps}
            onBlur={onBlur}
            ref={ref}
            placeholder="Шаг операции"
            required
            label='Задание'
            type="textarea"
            readOnly={!useTextMode}
            value={value?.block ? `${value.block.title}` : ''}
            onChange={(value) => {
              if (value.value) {
                onChange(
                  {
                    block: {
                      title:  value.value
                    },
                  }
                )
              } else {
                onChange( { block: undefined})
              }
              
            }}
          />
          <Switch
            size="m"
            label="Ввести вручную"
            checked={useTextMode}
            onChange={({ checked }) => setUseTextMode(checked)}
          />
        </Space>

        <Sheet
          ref={refBottomSheet}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          snapPoints={[window.innerHeight - 65, 0]}
          detent="full-height"
          initialSnap={0}
        >
          <Sheet.Container>
            <Sheet.Header>
              <Theme preset={presetGpnDefault}>
                <BottomSheetTitleHeader
                  title='Выбор задания'
                  onClose={() => {
                    refBottomSheet.current?.snapTo(1)
                  }}
                />
              </Theme>
            </Sheet.Header>
            <Sheet.Content disableDrag={true}>
              <Sheet.Scroller draggableAt="top">
                <Theme preset={presetGpnDefault}>
                  <Space spaceBetween direction="vertical" style={{ paddingBottom: 80 }} >
                    <>
                      {!serviceType && <ServiceTypesList
                        onSelect={(item) => { setServiceType(item) }}
                      />
                      }
                      {serviceType && !serviceOperation && <ServiceOperationsList
                        serviceTypeId={serviceType.id}
                        onSelect={(item) => { setServiceOperation(item) }}
                      />
                      }
                      {serviceOperation && !serviceBlock && <ServiceBlocksList
                        operationId={serviceOperation.id}
                        onSelect={(item) => {
                          setServiceBlock(item)
                          setIsDialogOpen(false)
                          onChange(
                            {
                              service: serviceType,
                              operation: serviceOperation,
                              block: item,
                            } as TaskJobValue
                          )
                        }}
                      />
                      }
                    </>
                    <div
                      style={{
                        backgroundColor: "#ffffff",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: '56px',
                      }}
                    >
                      <Divider />
                      <Button
                        form="round"
                        label="Назад"
                        view="ghost"
                        disabled={!!!serviceType}
                        style={{
                          position: "absolute",
                          marginLeft: 'var(--space-l)',
                          marginTop: '8px',
                          width: '50%',
                        }}
                        onClick={() => {
                          if (serviceOperation) {
                            setServiceOperation(undefined)
                          } else if (serviceType) {
                            setServiceType(undefined)
                          }
                        }}
                      />
                    </div>
                  </Space>
                </Theme>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    );
  };

  return <Controller
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    control={control || context.control}
    shouldUnregister={shouldUnregister}
    render={renderSelectJobTask}
  />
}