import { FC } from "react"
import { Space } from "../../../../components/Space"
import { Text } from "@consta/uikit/Text"
import { Badge } from "@consta/uikit/Badge"


interface Props {
    title: string
    count: number
}

export const EquipmentGroup: FC<Props> = ({ title, count }) => {
    return <Space
        size="xs"
        style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
            paddingTop: 'var(--space-s)',
            paddingBottom: 'var(--space-s)',
        }}>
        <Text view="primary" weight="bold">{title}</Text>
        {count > 0 ? <Badge form="round" label={count.toString()} status="system" style={{ marginLeft: 2 }} /> : <></>}
    </Space>
}