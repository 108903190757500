import { useEffect, useState } from "react";
import { TextField } from "@consta/uikit/TextField";
import { SelectWorkPlaceDialog } from "../../../../../components/SelectWorkPlace/components/SelectWorkPlaceDialog";
import { Text } from '@consta/uikit/Text';
import toast from "react-hot-toast";
import { Space } from "../../../../../components/Space";
import { Button } from "@consta/uikit/Button";
import { IconTrash } from '@consta/icons/IconTrash';




export const SelectDestinationPointController = ({
    fields,
    field,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    index,
    move,
    remove,
    ...fieldProps
}: any) => {
    const { onChange, onBlur, value, ref } = field

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    return <div key={field.id}>
        <Space spaceBetween style={{alignItems: 'end'}}>
            <TextField
                onClick={() => { setIsDialogOpen(true) }}
                style={{ width: '100%' }}
                {...fieldProps}
                onBlur={onBlur}
                ref={ref}
                placeholder="Выберите место"
                label={`Точка назначения ${index + 1}`}
                required={rules?.required}
                type="textarea"
                readOnly
                value={value?.oilField && value?.cluster ? `${value.oilField.title} / ${value.cluster.title} ${value?.well ? '/' : ''} ${value?.well ? value?.well!.title : ''}` : ''}
            />

            {index > 0 ? <Button
                iconRight={IconTrash}
                onlyIcon
                view="ghost"
                size="m"
                onClick={() => remove(index)}
            /> : <></>}

        </Space>

        <SelectWorkPlaceDialog
            level={2}
            isOpen={isDialogOpen}
            onClose={() => { setIsDialogOpen(false) }}
            onSelect={(workPlace) => {
                setIsDialogOpen(false)
                onChange({
                    oilField: workPlace.oilField,
                    cluster: workPlace.cluster,
                    well: workPlace.well,
                })
            }}
        />
    </div>
}