import { FC } from "react"
import { Space } from "./Space"
import { Text } from "@consta/uikit/Text"
import { IconClose } from '@consta/icons/IconClose';
import { Button } from "@consta/uikit/Button";
import { Divider } from "./Divider";


interface Props {
    title: string,
    onBackPress: () => void
}

export const AppBarBottomSheet: FC<Props> = ({ title, onBackPress }) => {
    return <div>
        <Space
            align="center"
            spaceBetween
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
                paddingTop: 'var(--space-s)',
            }}
        >
            <Text
            view="primary"
            size="xl"
            weight="semibold"
        >
                {title}
            </Text>
            <Button
                label="Назад"
                view="ghost"
                size="s"
                iconLeft={IconClose}
                onlyIcon
                onClick={onBackPress} />
        </Space>
        <Divider style={{ marginTop: 16 }} />
    </div>

}