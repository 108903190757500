import { SkeletonBrick } from "@consta/uikit/Skeleton"
import { Space } from "../../../../components/Space"

export const StepDetailsSkeleton = () => {
    return <Space
        size="xs"
        direction="vertical"
        style={{
            paddingLeft: 'var(--space-s)',
            paddingRight: 'var(--space-s)',
        }}
    >
        <SkeletonBrick height={140} />
        <SkeletonBrick height={140} />
    </Space>
}