import { useGetRequestsQuery } from "./services"
import { RequestItem } from "./components/RequestItem"
import { Fragment, useState } from "react"
import { Divider } from "../../../../components/Divider"
import { ErrorDataPlaceholder } from "../../../../components/ErrorDataPlaceholder"
import { EmptyDataPlaceholder } from "../../../../components/EmptyDataPlaceholder"
import { OfflineRequest } from "./types"
import { MechanicManageRequestDialog } from "./components/MechanicManageRequestDialog"
import { SkeletonBrick } from '@consta/uikit/Skeleton';
import { Space } from "../../../../components/Space"
import { useGetDisengageTypesQuery } from "../../../../mdm/services"

export const MechanicOfflineRequests = () => {
    const { data, error, isFetching, refetch } = useGetRequestsQuery()
    const { isFetching: disengageTypesIsLoading  } = useGetDisengageTypesQuery()
    const [request, setRequest] = useState<OfflineRequest | null>(null)

    return <div>
        {(!error && (!data || isFetching || disengageTypesIsLoading)) &&
            <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                }}
            >
                <SkeletonBrick height={80} />
                <SkeletonBrick height={80} />
                <SkeletonBrick height={80} />
                <SkeletonBrick height={80} />
            </Space>
        }

        {error && <ErrorDataPlaceholder
            title="Произошла ошибка"
            description="Не удалось загрузить список заявок"
        />}

        {(data && !isFetching) && <div style={{
            marginTop: 'var(--space-m)',
            marginBottom: 'var(--space-m)',
        }}>
            {data.map((request, index) => {
                return <Fragment key={request.id}>
                    <RequestItem
                        onPress={() => setRequest(request)}
                        item={request}
                    />
                    {index !== data.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                </Fragment>
            })
            }
        </div>}

        {(data && data.length === 0 && !isFetching) && <EmptyDataPlaceholder
            title="Здесь пусто"
            description="У вас нет заявок для обработки"
        />}

        {request && <MechanicManageRequestDialog
            request={request}
            onClose={(update) => {
                setRequest(null)
                if (update) {
                    refetch()
                }
            }}
        />}

    </div>
}