import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { ConfirmResultRequest, DriverStartDowntimeRequest, LoadingCompletedRequest, LoadingStartedRequest, MovementCompletedRequest, MovementStartedRequest, RideCompletedRequest, RideContinuedRequest, RideStartedRequest } from './types'

export const driverStatesApi = createApi({
    reducerPath: 'driverScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        movementStarted: builder.mutation<any, MovementStartedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/movementStarted',
                method: 'POST',
                body: request
            }),
        }),
        movementCompleted: builder.mutation<any, MovementCompletedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/movementCompleted',
                method: 'POST',
                body: request
            }),
        }),
        loadingStarted: builder.mutation<any, LoadingStartedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/loadingStarted',
                method: 'POST',
                body: request
            }),
        }),
        loadingCompleted: builder.mutation<any, LoadingCompletedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/loadingCompleted',
                method: 'POST',
                body: request
            }),
        }),
        startWork: builder.mutation<void, undefined>({
            query: () => ({
                url: '/mobile/v2/driver/tasks/startWork',
                method: 'POST',
            }),
        }),
        endWork: builder.mutation<void, undefined>({
            query: () => ({
                url: '/mobile/v2/driver/tasks/endWork',
                method: 'POST',
            }),
        }),
        startDowntime: builder.mutation<void, DriverStartDowntimeRequest>({
            query: (request) => ({
                url: '/mobile/driver/tasks/startDowntime',
                method: 'POST',
                body: request
            }),
        }),
        endDowntime: builder.mutation<void, string>({
            query: (id) => ({
                url: `/mobile/driver/tasks/endDowntime/${id}`,
                method: 'POST',
            }),
        }),
        confirmResult: builder.mutation<void, ConfirmResultRequest>({
            query: (request) => ({
                url: '/mobile/driver/requests/confirmApprovalResultAccepted',
                method: 'POST',
                body: request,
            }),
        }),
        rideStarted: builder.mutation<void, RideStartedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/rideStarted',
                method: 'POST',
                body: request,
            }),
        }),
        rideContinued: builder.mutation<void, RideContinuedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/rideContinued',
                method: 'POST',
                body: request,
            }),
        }),
        rideCompleted: builder.mutation<void, RideCompletedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/rideCompleted',
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const {
    useMovementStartedMutation,
    useMovementCompletedMutation,
    useLoadingStartedMutation,
    useLoadingCompletedMutation,
    useStartWorkMutation,
    useEndWorkMutation,
    useStartDowntimeMutation,
    useEndDowntimeMutation,
    useConfirmResultMutation,
    useRideCompletedMutation,
    useRideContinuedMutation,
    useRideStartedMutation,
 } = driverStatesApi