import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DriverEvent, DriverSlot } from "./types";
import { EquipmentState } from "../../components/EquipmentStateLabel/types";
import { StorageKeys } from "../../utils/storage-keys";

export interface DriverState {
    currentSlot: DriverSlot | null | undefined
    currentEvent: DriverEvent | null | undefined
    equipmentState: EquipmentState | null | undefined
    regNumber: string | undefined
    hasApprovedDeviceId: boolean
    forcedUnbindDialog: boolean
}

const initialState: DriverState = {
    currentSlot: null,
    currentEvent: null,
    equipmentState: null,
    regNumber: undefined,
    hasApprovedDeviceId: localStorage.getItem(StorageKeys.deviceId) != null,
    forcedUnbindDialog: false,
}

export const driverSlice = createSlice({
    name: 'driver',
    initialState,
    reducers: {
        updateCurrentSlot: (state, action: PayloadAction<DriverSlot | null>) => {
            state.currentSlot = action.payload
        },
        updateCurrentEvent: (state, action: PayloadAction<DriverEvent | null>) => {
            state.currentEvent = action.payload
        },
        updateEquipmentState: (state, action: PayloadAction<EquipmentState | null>) => {
            state.equipmentState = action.payload
        },
        updateRegNumber: (state, action: PayloadAction<string | undefined>) => {
            state.regNumber = action.payload
        },
        updateHasApprovedDeviceId: (state, action: PayloadAction<boolean>) => {
            state.hasApprovedDeviceId = action.payload
        },
        updateForcedUnbindDialog: (state, action: PayloadAction<boolean>) => {
            state.forcedUnbindDialog = action.payload
        },
    },
})

export const {
    updateCurrentSlot,
    updateCurrentEvent,
    updateEquipmentState,
    updateRegNumber,
    updateHasApprovedDeviceId,
    updateForcedUnbindDialog,
} = driverSlice.actions

export default driverSlice.reducer