import { Sidebar } from '@consta/uikit/Sidebar';
import { Text } from '@consta/uikit/Text';
import { FC } from 'react';
import { Divider } from './Divider';
import { Button } from '@consta/uikit/Button';
import { IconExit } from '@consta/icons/IconExit';
import { IconRoute } from '@consta/icons/IconRoute';
import { Space } from './Space';
import { logout, userApi } from '../user/services';
import { keycloak } from '../Keycloak';
import { mapApi, useGetPoiQuery, useGetRoadsQuery, useLazyGetPoiQuery, useLazyGetRoadsQuery } from './Map/services';
import { useDispatch } from 'react-redux';
import { updateLastUpdateDate } from './Map/slice';
import { getTime } from 'date-fns';


interface Props {
    isOpen: boolean
    onClickOutside: () => void
    onLogout?: () => void
    additional?: React.ReactNode
    actions?: React.ReactNode[]
}

export const ProfileSideBar: FC<Props> = ({ isOpen, onLogout, onClickOutside, additional, actions }) => {
    var packageJson = require('../../package.json')
    const dispath = useDispatch()

    // const [refecthRoads ] = useLazyGetRoadsQuery()
    // const [refecthPoi ] = useLazyGetPoiQuery()

    const { data: user } = userApi.endpoints.getUser.useQueryState(undefined);
    let userRole = function (): string {
        if (user?.selectedTenant) {
            return user?.tenants[user?.selectedTenant].roles.at(0)?.title ?? 'Роль не найдена'
        } else {
            return 'Роль не найдена'
        }
    }

    return <Sidebar
        style={{ zIndex: 100 }}
        isOpen={isOpen}
        position='left'
        onClickOutside={() => onClickOutside()}
        size='3/4'
    >   <Space
        style={{ height: '100%' }}
        direction='vertical'
        spaceBetween={true}
    >
            <div>
                <Text
                    as="p"
                    size="l"
                    view="primary"
                    weight="semibold"
                    style={{ marginLeft: 'var(--space-l)' }}
                >
                    {userRole()}
                </Text>
                {additional &&
                    <div style={{
                        paddingLeft: 'var(--space-l)',
                        paddingRight: 'var(--space-l)',
                        paddingBottom: 'var(--space-l)'
                    }}>
                        {additional}
                    </div>
                }
                <Sidebar.Actions >
                    {actions}
                    <Button
                        key='logout_btn'
                        size="m"
                        view="clear"
                        label="Обновить граф дорог"
                        iconLeft={IconRoute}
                        onClick={async () => {
                            // refecthRoads(true)
                            // refecthPoi(true)
                            dispath(updateLastUpdateDate(getTime(new Date())))
                        }}
                    />
                    <Divider />
                    <Button
                        key='update_arc_btn'
                        size="m"
                        view="clear"
                        label="Выйти из приложения"
                        iconLeft={IconExit}
                        onClick={async () => {
                            if (onLogout) {
                                onLogout()
                            } else {
                                await logout()
                                await keycloak?.logout()
                            }
                        }}
                    />
                    <Divider />
                </Sidebar.Actions>
            </div>
            <Text
                as="p"
                size="m"
                view="secondary"
                weight="regular"
                style={{ marginLeft: 'var(--space-l)' }}
            >Версия приложения: {packageJson.version}</Text>
        </Space>
    </Sidebar>
}