import { createApi } from '@reduxjs/toolkit/query/react'
import { BoardingStartedRequest, ExecutionStartedRequest, MovementStartedRequest, RideCompletedRequest, RideContinuedRequest, RideStartedRequest } from './types'
import { baseQuery } from '../../../../base-service'

export const driverStatesApi = createApi({
    reducerPath: 'driverScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        movementStarted: builder.mutation<any, MovementStartedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/movementStarted',
                method: 'POST',
                body: request
            }),
        }),
        rideStarted: builder.mutation<void, RideStartedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/rideStarted',
                method: 'POST',
                body: request,
            }),
        }),
        rideContinued: builder.mutation<void, RideContinuedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/rideContinued',
                method: 'POST',
                body: request,
            }),
        }),
        rideCompleted: builder.mutation<void, RideCompletedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/rideCompleted',
                method: 'POST',
                body: request,
            }),
        }),
        boardingStarted: builder.mutation<void, BoardingStartedRequest>({
            query: (request) => ({
                url: '/mobile/v2/driver/boardingStarted',
                method: 'POST',
                body: request,
            }),
        }),
        executionStarted: builder.mutation<void, ExecutionStartedRequest>({
            query: (request) => ({
                url: 'mobile/v2/driver/requestExecutionStarted',
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const {
    useMovementStartedMutation,
    useRideCompletedMutation,
    useRideContinuedMutation,
    useRideStartedMutation,
    useBoardingStartedMutation,
    useExecutionStartedMutation,
} = driverStatesApi