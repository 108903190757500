import { Badge } from '@consta/uikit/Badge';
import { FC } from 'react';
import { DriverPassengerRequestState } from '../../types';

interface Props {
    state: DriverPassengerRequestState
}

export const RequestStateLabel: FC<Props> = ({ state }) => {
    const labels: Record<DriverPassengerRequestState, string> = {
        [DriverPassengerRequestState.PLANNED]: "Запланирован",
        [DriverPassengerRequestState.DELAYED]: "Задержка",
        [DriverPassengerRequestState.IN_PROGRESS]: "В работе",
        [DriverPassengerRequestState.CONFIRMED]: "Подтвержено",
        [DriverPassengerRequestState.LOCKED_AUTO]: "Закреплен",
        [DriverPassengerRequestState.LOCKED_MANUAL]: "Закреплен",
        [DriverPassengerRequestState.LOCKED]: "Закреплен",
        [DriverPassengerRequestState.COMPLETED]: "Завершен",
        [DriverPassengerRequestState.NOT_PERFORMED]: "Не подтвержено",
        [DriverPassengerRequestState.DEFICIT]: "Дефицит",
        [DriverPassengerRequestState.PART_COMPLETED]: "Завершен частично",
    }

    const status: Record<DriverPassengerRequestState, "normal" | "error" | "success" | "warning" | "system" | undefined> = {
        [DriverPassengerRequestState.PLANNED]: "system",
        [DriverPassengerRequestState.DELAYED]: "warning",
        [DriverPassengerRequestState.IN_PROGRESS]: "normal",
        [DriverPassengerRequestState.CONFIRMED]: "normal",
        [DriverPassengerRequestState.LOCKED_AUTO]: "warning",
        [DriverPassengerRequestState.LOCKED_MANUAL]: "warning",
        [DriverPassengerRequestState.LOCKED]: "warning",
        [DriverPassengerRequestState.NOT_PERFORMED]: "system",
        [DriverPassengerRequestState.COMPLETED]: "success",
        [DriverPassengerRequestState.DEFICIT]: "error",
        [DriverPassengerRequestState.PART_COMPLETED]: "normal",
    }

    return <Badge status={status[state]} label={labels[state]} />
}