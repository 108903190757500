import { Space } from "../../../../components/Space"
import { Text } from "@consta/uikit/Text"
import { RegisterPlate } from "../../../../components/RegisterPlate"
import { MasterRequestResource, SlotType } from "../types";
import { FC, useEffect, useState } from "react";
import { EquipmentStateLabel } from "../../../../components/EquipmentStateLabel";
import { Button } from "@consta/uikit/Button";
import { IconGeo } from '@consta/icons/IconGeo';
import { IconAllDone } from '@consta/icons/IconAllDone';
import { Modal } from '@consta/uikit/Modal';
import { useArrivalConfirmedMutation, useGetRequestDetailsQuery, useWorkCompletedMutation } from "../services";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { updateCenterPoint } from "../../../../components/Map/slice";
import { RootState } from "../../../../store";
import { EquipmentState } from "../../../../components/EquipmentStateLabel/types";
import { MasterRequestState } from "../../home/types";
import { StorageKeys } from "../../../../utils/storage-keys";


interface Props {
    equipmentId: string
    equipment: MasterRequestResource
    stepState: MasterRequestState
    stepId: string
}

export const StepEquipmentItem: FC<Props> = ({ equipmentId, equipment, stepState, stepId }) => {
    const dispatch = useDispatch()
    const equipmentPositions = useSelector((state: RootState) => state.map.equipmentPositions)
    const request = useSelector((state: RootState) => state.master.selectedRequest)!

    const [confirmeArrive, { isLoading: confirmeArriveLoading, isError: confirmeArriveError, isSuccess: confirmeArriveSucces }] = useArrivalConfirmedMutation()
    const [workCompleted, { isLoading: workCompletedLoading, isError: workCompletedError, isSuccess: workCompletedSucces }] = useWorkCompletedMutation()
    const { refetch } = useGetRequestDetailsQuery(request.id, { skip: !confirmeArriveSucces && !workCompletedSucces })

    const [isModalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [isModalWorkCompleteOpen, setModalWorkCompleteOpen] = useState(false);

    useEffect(() => {
        if (confirmeArriveError) {
            toast.error('Произошла ошибка. Не удалось подтвердить прибытие')
        }
        if (workCompletedError) {
            toast.error('Произошла ошибка. Не удалось отпустить СПТ')
        }
    }, [confirmeArriveError, workCompletedError])

    useEffect(() => {
        if (confirmeArriveSucces || workCompletedSucces) {
            refetch()
        }
    }, [confirmeArriveSucces, workCompletedSucces])

    return <>
        <Space
            direction="vertical"
            size="xs"
            style={{
                padding: 10,
                backgroundColor: "#F5F5F5",
                borderRadius: 4,
            }}
        >
            <EquipmentStateLabel state={equipment.resourceState} />
            <Space spaceBetween>
                <Space size="xs" style={{ width: '80%' }}>
                    <RegisterPlate regNumber={equipment.registrationPlateNumber} />
                    <Text truncate view="primary" weight="regular">{equipment.title}</Text>
                </Space>
                <Button
                    label="Центр"
                    view="ghost"
                    size="s"
                    iconLeft={IconGeo}
                    onlyIcon
                    onClick={() => {
                        const position = equipmentPositions.find(element => element.equipmentId == equipmentId)
                        if (position) {
                            dispatch(updateCenterPoint({ lat: position.geoY, lng: position.geoX }))
                        } else {
                            toast.error('Координаты техники не найдены')
                        }
                    }}
                />
            </Space>

            {stepId == equipment.currentSlot?.currentSlot?.activityId
                && equipment.resourceState == EquipmentState.STATE_ARRIVAL_UNCONFIRMED
                && equipment.currentSlot?.currentSlot?.type == SlotType.ResourceTransportationSlot
                && localStorage.getItem(StorageKeys.passangerModeEnabled) == 'false'
                ? <Button
                    size="s"
                    label="Подтвердить прибытие"
                    view="ghost"
                    iconLeft={IconAllDone}
                    loading={confirmeArriveLoading}
                    onClick={() => setModalConfirmOpen(true)}
                /> : <></>}

            {stepId == equipment.currentSlot?.currentSlot?.activityId &&
                equipment.resourceState == EquipmentState.STATE_WORK_IN_PROGRESS
                && equipment.currentSlot?.currentSlot?.type == SlotType.ResourceOperationStepSlot
                && localStorage.getItem(StorageKeys.passangerModeEnabled) == 'false'
                ? <Button
                    size="s"
                    label="Отпустить СПТ"
                    view="ghost"
                    iconLeft={IconAllDone}
                    loading={workCompletedLoading}
                    onClick={() => setModalWorkCompleteOpen(true)}
                /> : <></>}
        </Space>

        <Modal
            isOpen={isModalConfirmOpen}
            hasOverlay
            onClickOutside={() => setModalConfirmOpen(false)}
            onEsc={() => setModalConfirmOpen(false)}
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="l" view="primary" lineHeight="m" weight="semibold">{equipment.registrationPlateNumber} / {equipment.title}</Text>
                <Text as="p" size="m" view="secondary" lineHeight="m">Подтвердите прибытие техники на место работ</Text>
                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            onClick={() => {
                                setModalConfirmOpen(false)
                                confirmeArrive({ resourceId: equipmentId })
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => setModalConfirmOpen(false)}
                        />
                    </div>
                </Space>
            </div>
        </Modal>

        <Modal
            isOpen={isModalWorkCompleteOpen}
            hasOverlay
            onClickOutside={() => setModalWorkCompleteOpen(false)}
            onEsc={() => setModalWorkCompleteOpen(false)}
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="l" view="primary" lineHeight="m" weight="semibold">{equipment.registrationPlateNumber} / {equipment.title}</Text>
                <Text as="p" size="m" view="secondary" lineHeight="m">Подтвердите зарешение работы СПТ</Text>
                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            onClick={() => {
                                setModalWorkCompleteOpen(false)
                                workCompleted({ resourceId: equipmentId, slotId: equipment.currentSlot.currentSlot.id })
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => setModalWorkCompleteOpen(false)}
                        />
                    </div>
                </Space>
            </div>
        </Modal>
    </>
}
