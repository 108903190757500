import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { MasterNotification, NoticeStatusRequest } from './types'

export const masterNotificationsApi = createApi({
    reducerPath: 'masterNotificationsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getNewNoticeList: builder.query<MasterNotification[], void>({
            query: () => ({
                url: '/mobile/v2/master/newNoticeList',
                method: 'GET',
            }),
            structuralSharing: false,
        }),
        noticeStatus: builder.mutation<void, NoticeStatusRequest>({
            query: (body) => ({
                url: '/mobile/v2/master/noticeStatus',
                method: 'POST',
                body: body,
            }),
        }),
    }),
  })
  
  export const {
    useGetNewNoticeListQuery,
    useNoticeStatusMutation,
} = masterNotificationsApi