import type { CSSProperties, FC } from 'react';

import React from 'react';

interface Props {
  color?: string
  style?: CSSProperties | undefined
  height?: number
}

export const Divider: FC<Props> = React.memo(({ color = 'var(--color-bg-border)', style, height }) => {
  return (
    <div
      style={{
        background: color,
        width: '100%',
        height: height ?? '1px',
        ...style,
      }}
    />
  );
});
