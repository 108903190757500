import { TextField, useIMask } from "@consta/uikit/TextField"

export const PhoneTextFiled = ({ field }: any) => {
    const { inputRef } = useIMask({
        value: field.value,
        onChange: (value) => field.onChange(value),
        maskOptions: '+{7}(000)000-00-00',
    })

    return <TextField
        {...field}
        required
        inputRef={inputRef}
        onChange={(value) => field.onChange(value.value)}
        label='Телефон'
        placeholder="+7(___)___-__-__" />
}