import { createApi } from '@reduxjs/toolkit/query/react'
import { MechanicOutOfServiceRequest } from './types'
import { baseQuery } from '../../../../../base-service'

export const mechanicOutOfServiceApi = createApi({
    reducerPath: 'ssmMechanicOutOfServiceApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createOutOfService: builder.mutation<undefined, MechanicOutOfServiceRequest>({
            query: (request) => ({
                url: `/mobile/mechanic/outOfService`,
                method: 'POST',
                body: request
            }),
        }),
    }),
  })
  
  export const { useCreateOutOfServiceMutation } = mechanicOutOfServiceApi