import { Text } from "@consta/uikit/Text"
import { Button } from "@consta/uikit/Button";
import { Space } from "../../../components/Space";
import { logout } from "../../../user/services";
import { keycloak } from "../../../Keycloak";
import { useSendBindRequestMutation } from "./services";
import { useEffect, useRef, useState } from "react";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { Theme, presetGpnDefault } from "@consta/uikit/Theme";
import { BottomSheetTitleHeader } from "../../../components/BottomSheetTitleHeader";
import { EquipmetItemList } from "./components/EquipmetItemList";
import { useGetCurrentSlotQuery } from "../services";
import { useInterval, useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { updateHasApprovedDeviceId } from "../slice";
import toast, { Toaster } from 'react-hot-toast';
import { RootState } from "../../../store";


export const DriverUnbind = () => {
    const dispatch = useDispatch()

    const forcedUnbindDialog = useSelector((state: RootState) => state.driver.forcedUnbindDialog)

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [requestSent, setRequestSent] = useState<boolean>(false)
    const [requestDeclined, setRequestDeclined] = useState<boolean>(false)
    const [declineReason, setDeclineReason] = useState<string | undefined>(undefined)
    const refBottomSheet = useRef<SheetRef>()

    const [sendRequest, { isLoading: sendRequestIsLoading, isError: sendRequestIsError, isSuccess: sendRequestIsSuccess, }] = useSendBindRequestMutation()
    const { error: updateError, refetch: updateRefetch, isFetching: updateIsFetching, isSuccess: updateIsSuccess } = useGetCurrentSlotQuery(undefined, { skip: !requestSent })

    useMount(() => {
        if (forcedUnbindDialog) {
            setIsDialogOpen(true)
        }
    })

    useEffect(() => {
        if (sendRequestIsError) {
            toast.error('Произошла ошибка. Не удалось привязать технику')
        }
        if (sendRequestIsSuccess) {
            toast.success('Заявка успешно отправлена')
            setRequestSent(true)
            setRequestDeclined(false)
        }
    }, [sendRequestIsError, sendRequestIsSuccess])

    useEffect(() => {
        if (updateIsSuccess) {
            dispatch(updateHasApprovedDeviceId(true))
        }
    }, [updateIsSuccess])

    useEffect(() => {
        if (updateError && 'status' in updateError) {
            if (updateError.status == 423) {
                setRequestDeclined(true)
                setDeclineReason(updateError.data as string)
            }
        }
    }, [updateError])

    useInterval(
        () => {
            if (requestSent && !requestDeclined && !updateIsSuccess) {
                updateRefetch()
            }
        },
        15 * 1000,
    )

    return <>
        <Space
            direction="vertical"
            style={{
                textAlign: 'center',
                width: '100%',
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)"
            }}>
            {!requestSent && !requestDeclined ? <Text
                view="primary"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}>
                Данное устройство не привязано ни к какому транспортному средству. Отправить заявку на привязку?
            </Text> : <></>}

            {requestSent && requestDeclined ? <Text
                view="primary"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    whiteSpace:'pre-line',
                }}>
                {declineReason ? `Ваша заявка на привязку техники была отклонена ${"\n"} Причина: ${declineReason}` : 'Ваша заявка на привязку техники была отклонена'}
            </Text> : <></>}

            {requestSent && !requestDeclined ? <Text
                view="primary"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}>
                Ваша заявка на привязку техники отправлена. Ожидайте подтверждения
            </Text> : <></>}


            {!requestSent || requestDeclined ? <Button
                style={{
                    marginLeft: 'var(--space-l)',
                    marginRight: 'var(--space-l)',
                }}
                loading={sendRequestIsLoading}
                size="m"
                view="primary"
                width="default"
                label="Отправить заявку"
                form="round"
                onClick={() => {
                    setIsDialogOpen(true)
                }}
            /> : <></>}

            {requestSent && !requestDeclined ? <Button
                style={{
                    marginLeft: 'var(--space-l)',
                    marginRight: 'var(--space-l)',
                }}
                loading={updateIsFetching}
                size="m"
                view="primary"
                width="default"
                label="Обновить"
                form="round"
                onClick={() => {
                    updateRefetch()
                }}
            /> : <></>}
        </Space>

        <Button
            label="Выйти"
            view="clear"
            onClick={async () => {
                await logout()
                await keycloak?.logout()
            }}
            style={{
                position: "absolute",
                right: 'var(--space-l)',
                top: 'var(--space-l)',
            }}
        />

        <Sheet
            ref={refBottomSheet}
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            snapPoints={[window.innerHeight - 65, 0]}
            detent="full-height"
            initialSnap={0}
        >
            <Sheet.Container>
                <Sheet.Header>
                    <Theme preset={presetGpnDefault}>
                        <BottomSheetTitleHeader
                            title='Выберите технику'
                            onClose={() => {
                                refBottomSheet.current?.snapTo(1)
                            }}
                        />
                    </Theme>
                </Sheet.Header>
                <Sheet.Content disableDrag={true}>
                    <Sheet.Scroller draggableAt="top">
                        <Theme preset={presetGpnDefault} style={{ paddingBottom: 80 }}>
                            <EquipmetItemList
                                onSelect={(item) => {
                                    setIsDialogOpen(false)
                                    sendRequest({ resourceId: item.id })
                                }}
                            />
                        </Theme>
                    </Sheet.Scroller>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    </>
}