import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../base-service'
import { StorageKeys } from '../../utils/storage-keys'
import { MapApiResponse, MapPoiContent } from './types'

export const mapApi = createApi({
    reducerPath: 'ssmMapApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getRoads: builder.query<any, boolean>({
            async queryFn(fromRemote, _queryApi, _extraOptions, fetchWithBQ) {
                const arcsCache = localStorage.getItem(StorageKeys.mapCacheArcs)

                if (!fromRemote && arcsCache) {
                    return { data: JSON.parse(arcsCache) }
                } else {
                    const response = await fetchWithBQ('/mobile/maps/road/arc')

                    if (response.error) {
                        return { error: response.error }
                    }
                    localStorage.setItem(StorageKeys.mapCacheArcs, JSON.stringify(response.data as MapApiResponse))

                    return { data: response.data as any }
                }
            },
            // keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
        }),
        getPoi: builder.query<any, boolean>({
            async queryFn(fromRemote, _queryApi, _extraOptions, fetchWithBQ) {
                const poiCache = localStorage.getItem(StorageKeys.mapCachePoi)

                if (!fromRemote && poiCache) {
                    return { data: JSON.parse(poiCache) }
                } else {
                    const response = await fetchWithBQ('/mobile/mdm/Poi/Page?size=2147483647&sort=title')

                    if (response.error) {
                        return { error: response.error }
                    }

                    const content = {
                        "type": "FeatureCollection",
                        "features": (response.data as MapPoiContent).content
                            .filter(element => !element.types.includes('MANUAL_POINT'))
                            .map((element) => ({
                                "type": "Feature",
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": element.geometry.coordinates.at(0)
                                },
                                "properties": {
                                    "name": element.id,
                                    "title": element.title,
                                }
                            }))
                    }

                    localStorage.setItem(StorageKeys.mapCachePoi, JSON.stringify(content as MapApiResponse))

                    return { data: content }
                }
            },
            // keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
        }),
    }),
})

export const { useGetRoadsQuery, useGetPoiQuery, useLazyGetRoadsQuery, useLazyGetPoiQuery } = mapApi