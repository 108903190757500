import { FC, Fragment } from "react";
import { DriverScheduleData, DriverSlot } from "../../../types";
import { GetShiftPeriodDescription, GetShiftPeriodType, ShiftType } from "../../../../../utils/workShifts";
import { parseISO } from "date-fns";
import { CollapseGroup, DefaultItem } from "@consta/uikit/CollapseGroup";
import { Divider } from "../../../../../components/Divider";
import { ScheduleItem } from "./ScheduleItem";
import styles from './styles.module.css';
import { ShiftLabel } from "../../../../../components/ShiftLabel";


interface Props {
    data: DriverScheduleData,
}

export const Schedule: FC<Props> = ({ data }) => {
    const previousShiftSlots: DriverSlot[] = data.schedule?.slots?.filter((slot) => {
        var shiftType = GetShiftPeriodType({ workShift: data.shift, date: parseISO(slot.beginDate) })
        return shiftType === ShiftType.previous
    }) ?? []

    const currentShiftSlots: DriverSlot[] = data.schedule?.slots?.filter((slot) => {
        var shiftType = GetShiftPeriodType({ workShift: data.shift, date: parseISO(slot.beginDate) })
        return shiftType === ShiftType.current
    }) ?? []

    const nextShiftSlots: DriverSlot[] = data.schedule?.slots?.filter((slot) => {
        var shiftType = GetShiftPeriodType({ workShift: data.shift, date: parseISO(slot.beginDate) })
        return shiftType === ShiftType.next
    }) ?? []

    const items: DefaultItem[] = [
        {
            label: <ShiftLabel
                description={GetShiftPeriodDescription({ workShift: data.shift, type: ShiftType.previous })}
                count={previousShiftSlots.length}
            />,
            content: <div>
                {previousShiftSlots.map((slot, index) => {
                    return <Fragment key={slot.id}>
                        {index == 0 && <div style={{ height: 'var(--space-s)'}}/>}
                        <ScheduleItem item={slot} />
                        {index !== previousShiftSlots.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }}/>}
                        {index == previousShiftSlots.length - 1 && <div style={{ height: 'var(--space-s)' }} />}
                    </Fragment>
                })
                }
            </div>,
        },
        {
            label: <ShiftLabel
                description={GetShiftPeriodDescription({ workShift: data.shift, type: ShiftType.current })}
                count={currentShiftSlots.length}
            />,
            content: <div>
                {currentShiftSlots.map((slot, index) => {
                    return <Fragment key={slot.id}>
                        {index == 0 && <div style={{ height: 'var(--space-s)'}}/>}
                        <ScheduleItem item={slot} />
                        {index !== currentShiftSlots.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }}/>}
                        {index == currentShiftSlots.length - 1 && <div style={{ height: 'var(--space-s)' }} />}
                    </Fragment>
                })
                }
            </div>,
        },
        {
            label: <ShiftLabel
                description={GetShiftPeriodDescription({ workShift: data.shift, type: ShiftType.next })}
                count={nextShiftSlots.length}
            />,
            content: <div>
                {nextShiftSlots.map((slot, index) => {
                    return <Fragment key={slot.id}>
                        {index == 0 && <div style={{ height: 'var(--space-s)'}}/>}
                        <ScheduleItem item={slot} />
                        {index !== nextShiftSlots.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }}/>}
                        {index == nextShiftSlots.length - 1 && <div style={{ height: 'var(--space-s)' }} />}
                    </Fragment>
                })
                }
            </div>,
        }
    ]

    return < CollapseGroup
        className={styles.collapseGroup}
        divider={true}
        items={items}
        size="l"
        view="ghost"
        iconPosition="right"
    />
}