import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { logout, userApi } from '../../user/services';
import { DriverHome } from '../driver/home';
import { MechanicHome } from '../mechanic/home';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { keycloak } from '../../Keycloak';
import { MasterHome } from '../master/home';
import { DriverUnbind } from '../driver/unbind';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserNotFoundPage } from './components/UserNotFoundPage';
import { DriverPassengerHome } from '../driver-passenger/home';
import { mdmApi, useGetResourceStateQuery } from '../../mdm/services';
import { StorageKeys } from '../../utils/storage-keys';
import { useEffect } from 'react';
import { updateUsername } from '../../user/slice';
import { UserSelectRole } from './components/UserSelectRole';


export const Root = () => {
  const { data: userData, error, isLoading: isLoadingUser } = userApi.endpoints.getUser.useQueryState(undefined)
  const { isLoading: isLoadingSettings } = mdmApi.endpoints.getSystemSettings.useQueryState(undefined)

  useGetResourceStateQuery(undefined, { skip: !userData })

  const dispatch = useDispatch()

  const hasApprovedDeviceId = useSelector((state: RootState) => state.driver.hasApprovedDeviceId)

  useEffect(() => {
    if (userData) {
      dispatch(updateUsername(userData.username))
    }
  }, [userData])

  if (error) {
    return <div style={{
      textAlign: 'center',
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)"
    }}>
      <Text as="p" size="l" view="primary" weight='bold'>
        Доступ запрещен
      </Text>
      <Text as="p" size="m" view="primary">
        {(error as Record<string, string>).error}
      </Text>
      <Button
        size="m"
        view="ghost"
        label="Выйти"
        width="default"
        onClick={async () => {
          await logout()
          await keycloak?.logout()
        }}
      />

    </div>
  }

  if (!userData || isLoadingUser || isLoadingSettings) {
    return <div style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)"
    }}>
      <ProgressSpin size="xl" />
    </div>
  }

  // Механизм выбора роли пока отключили до лучших времен
  // if (userData && !userData.selectedTenant && userData?.tenants && userData?.authorities?.length > 1) {
  //   return <UserSelectRole />
  // }

  if (userData?.selectedRole?.includes('DRIVER')) {
    if (hasApprovedDeviceId) {
      if (localStorage.getItem(StorageKeys.passangerModeEnabled) == 'true') {
        return <DriverPassengerHome />
      } else {
        return <DriverHome />
      }
    } else {
      return <DriverUnbind />
    }

  } else if (userData?.selectedRole?.includes('MECHANIC')) {
    return <MechanicHome />
  } else if (userData?.selectedRole?.includes('PWA_BRIGADEMASTER')) {
    return <MasterHome />
  } else {
    return <div style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)"
    }}>
      <UserNotFoundPage />
    </div>
  }
}
