import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { userApi } from './user/services'
import { driverScheduleApi } from './pages/driver/services'
import { mdmApi } from './mdm/services'
import { mapApi } from './components/Map/services'
import { mechanicApi } from './pages/mechanic/services'
import { mechanicSlice } from './pages/mechanic/slice'
import { mapSlice } from './components/Map/slice'
import { mechanicDetailsApi } from './pages/mechanic/home/components/EquipmentDetails/services'
import { mechanicOutOfServiceApi } from './pages/mechanic/home/components/NewOfflineRequest/services'
import { mechanicOfflineRequestsApi } from './pages/mechanic/requests/MechanicOfflineRequests/services'
import { masterApi } from './pages/master/services'
import { driverSlice } from './pages/driver/slice'
import { masterSlice } from './pages/master/slice'
import { masterRequestDetailsApi } from './pages/master/RequestDetails/services'
import { driverOutOfServiceApi } from './pages/driver/DriverOfflineRequest/services'
import { masterJobRequestApi } from './pages/master/requests/MasterJobRequest/services'
import { mechanicBindDeviceApi } from './pages/mechanic/BindDevice/services'
import { mechanicBindRequestsApi } from './pages/mechanic/requests/MechanicBindEquipmentRequests/services'
import { driverBindDeviceApi } from './pages/driver/unbind/services'
import { masterTransportationRequestApi } from './pages/master/requests/MasterTransportationRequest/services'
import { masterMaterialRequestApi } from './pages/master/requests/MasterMaterialRequest/service'
import { masterTeamTransferRequestApi } from './pages/master/requests/MasterTeamTransferRequest/services'
import { masterRouteRequestApi } from './pages/master/requests/MasterRouteRequest/services'
import { masterPassengerTransportRequestApi } from './pages/master/requests/MasterPassengerRequest/services'
import { masterNotificationsApi } from './pages/master/notifications/services'
import { driverPassengerScheduleApi } from './pages/driver-passenger/home/services'
import { driverPassengerRequestDetailsApi } from './pages/driver-passenger/PassengerRequestDetails/services'
import { driverPassengerSlice } from './pages/driver-passenger/slice'
import { baseErrorHandler } from './base-service'
import { userSlice } from './user/slice'

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [driverScheduleApi.reducerPath]: driverScheduleApi.reducer,
    [driverPassengerScheduleApi.reducerPath]: driverPassengerScheduleApi.reducer,
    [driverPassengerRequestDetailsApi.reducerPath]: driverPassengerRequestDetailsApi.reducer,
    [driverOutOfServiceApi.reducerPath]: driverOutOfServiceApi.reducer,
    [driverBindDeviceApi.reducerPath]: driverBindDeviceApi.reducer,
    [mdmApi.reducerPath]: mdmApi.reducer,
    [mapApi.reducerPath]: mapApi.reducer,
    [mechanicApi.reducerPath]: mechanicApi.reducer,
    [masterApi.reducerPath]: masterApi.reducer,
    [mechanicOutOfServiceApi.reducerPath]: mechanicOutOfServiceApi.reducer,
    [mechanicDetailsApi.reducerPath]: mechanicDetailsApi.reducer,
    [mechanicOfflineRequestsApi.reducerPath]: mechanicOfflineRequestsApi.reducer,
    [mechanicBindRequestsApi.reducerPath]: mechanicBindRequestsApi.reducer,
    [mechanicBindDeviceApi.reducerPath]: mechanicBindDeviceApi.reducer,
    [masterRequestDetailsApi.reducerPath]: masterRequestDetailsApi.reducer,
    [masterJobRequestApi.reducerPath]: masterJobRequestApi.reducer,
    [masterTransportationRequestApi.reducerPath]: masterTransportationRequestApi.reducer,
    [masterMaterialRequestApi.reducerPath]: masterMaterialRequestApi.reducer,
    [masterTeamTransferRequestApi.reducerPath]: masterTeamTransferRequestApi.reducer,
    [masterRouteRequestApi.reducerPath]: masterRouteRequestApi.reducer,
    [masterPassengerTransportRequestApi.reducerPath]: masterPassengerTransportRequestApi.reducer,
    [masterNotificationsApi.reducerPath]: masterNotificationsApi.reducer,
    mechanic: mechanicSlice.reducer,
    driver: driverSlice.reducer,
    driverPassenger: driverPassengerSlice.reducer,
    master: masterSlice.reducer,
    map: mapSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      driverScheduleApi.middleware,
      driverPassengerScheduleApi.middleware,
      driverOutOfServiceApi.middleware,
      driverBindDeviceApi.middleware,
      driverPassengerRequestDetailsApi.middleware,
      mdmApi.middleware,
      mapApi.middleware,
      mechanicApi.middleware,
      mechanicOutOfServiceApi.middleware,
      mechanicDetailsApi.middleware,
      mechanicOfflineRequestsApi.middleware,
      mechanicBindDeviceApi.middleware,
      mechanicBindRequestsApi.middleware,
      masterApi.middleware,
      masterRequestDetailsApi.middleware,
      masterJobRequestApi.middleware,
      masterTransportationRequestApi.middleware,
      masterMaterialRequestApi.middleware,
      masterTeamTransferRequestApi.middleware,
      masterRouteRequestApi.middleware,
      masterPassengerTransportRequestApi.middleware,
      masterNotificationsApi.middleware,
      baseErrorHandler,
    ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch