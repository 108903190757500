import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { parseISO } from 'date-fns'
import { DriverPassengerRequestDetails, DriverPassengerRequestResource, DriverPassengerRequestStep } from './types'

export const driverPassengerRequestDetailsApi = createApi({
    reducerPath: 'driverPassengerRequestDetailsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getRequestDetails: builder.query<DriverPassengerRequestDetails, string>({
            async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {

                type responseType = {
                    steps: Record<string, DriverPassengerRequestStep>
                    resources: Record<string, DriverPassengerRequestResource>
                }

                const mainResponse = await fetchWithBQ(`/mobile/v2/driver/JobScheduleRequest/passenger/${id}/view`)
                if (mainResponse.error) {
                    return { error: mainResponse.error }
                }

                const raw = mainResponse.data as responseType
                
                const result = {
                    steps: Object.keys(raw.steps).map((key) => {
                        const val = raw.steps[key]
                        val.id = key
                        return val
                    }).sort((a, b) => parseISO(a.dateTimeTo).getTime() - parseISO(b.dateTimeOf).getTime()),
                    resources: raw.resources
                } as DriverPassengerRequestDetails

                return { data: result}
            }
        }),
    }),
})

export const {
    useGetRequestDetailsQuery,
} = driverPassengerRequestDetailsApi