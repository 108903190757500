import { useRef, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TextField } from "@consta/uikit/TextField";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { Theme, presetGpnDefault } from "@consta/uikit/Theme";
import { BottomSheetTitleHeader } from "../../../../../components/BottomSheetTitleHeader";
import { EquipmentItemList } from "./components/EquipmentItemList";


export const SelectEquipmentItem = ({
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    ...fieldProps
}: any) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const context = useFormContext()
    const refBottomSheet = useRef<SheetRef>()

    const renderSelectEquipmentItem = ({ field }: any) => {
        const { onChange, onBlur, value, ref } = field

        return <div>
            <TextField
                onClick={() => {
                    setIsDialogOpen(true);
                }}
                style={{ width: '100%' }}
                {...fieldProps}
                onBlur={onBlur}
                ref={ref}
                placeholder="Выберите СПТ"
                required
                label='СПТ для объезда'
                type="textarea"
                readOnly
                value={value?.registrationPlateNumber ?? ''}
            />

            <Sheet
                ref={refBottomSheet}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                snapPoints={[window.innerHeight - 65, 0]}
                detent="full-height"
                initialSnap={0}
            >
                <Sheet.Container>
                    <Sheet.Header>
                        <Theme preset={presetGpnDefault}>
                            <BottomSheetTitleHeader
                                title='Выбор СПТ'
                                onClose={() => {
                                    refBottomSheet.current?.snapTo(1)
                                }}
                            />
                        </Theme>
                    </Sheet.Header>
                    <Sheet.Content disableDrag={true}>
                        <Sheet.Scroller draggableAt="top">
                            <Theme preset={presetGpnDefault}>
                                <EquipmentItemList onSelect={(item) => {
                                    onChange(item)
                                    setIsDialogOpen(false)
                                }} />
                            </Theme>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
        </div>
    }

    return <Controller
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        control={control || context.control}
        shouldUnregister={shouldUnregister}
        render={renderSelectEquipmentItem}
    />
}