import { FC, useState } from "react";
import { Modal } from '@consta/uikit/Modal';
import { DateTime } from '@consta/uikit/DateTime';
import { format, startOfMinute } from 'date-fns';
import { Button } from '@consta/uikit/Button';
import { Space } from "./Space";
import { Text } from "@consta/uikit/Text"
import ruLocale from 'date-fns/locale/ru';


interface Props {
    isOpen?: boolean
    onClickOutside: () => void
    onSelect: (result: Date) => void
    style?: React.CSSProperties
    containerId?: string
}

export const DateTimeDialog: FC<Props> = ({ isOpen, onClickOutside, onSelect, style, containerId }) => {
    const minDate = startOfMinute(new Date());
    const [date, setDate] = useState<Date | undefined>();
    const [time, setTime] = useState<Date | undefined>();

    return <Modal
        container={containerId ? document.getElementById(containerId)! as HTMLDivElement : undefined}
        isOpen={isOpen}
        hasOverlay
        onClickOutside={() => {
            setDate(undefined)
            setTime(undefined)
            onClickOutside()
        }}
        style={{
            padding: 20,
            zIndex: style?.zIndex
        }}
    >
        {!date && <DateTime
            type="date"
            view="classic"
            minDate={minDate}
            onChange={(value) => {
                setDate(value.value);
                setTime(minDate)
            }}
        />}

        {date && <Space
            size="s"
            direction="vertical"
        >
            <Text
                weight="bold"
                align="center"
            >
                {format(date, "dd MMMM", { locale: ruLocale })}
            </Text>
            <DateTime
                style={{ width: 200 }}
                type="time"
                onChange={(value) => setTime(value.value)}
                value={time}
                multiplicitySeconds={0}
            />
            <Button
                disabled={!time}
                form="round"
                label="Выбрать"
                onClick={() => {
                    const result = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        time!.getHours(),
                        time!.getMinutes(),
                        time!.getSeconds(),
                        0,
                    )
                    onSelect(result)
                    setDate(undefined)
                    setTime(undefined)
                }}
            />
        </Space>
        }
    </Modal>
}