import { Space } from "../../../components/Space"
import { Text } from '@consta/uikit/Text';
import { Button } from "@consta/uikit/Button";
import { logout } from "../../../user/services";
import { keycloak } from "../../../Keycloak";


export const UserNotFoundPage = () => {
    return <Space direction="vertical">
        <Text align="center">
            Не найдены необходимые роли
        </Text>
        <Button
            size="l"
            view="ghost"
            label="Выйти"
            width="default"
            onClick={async () => {
                await logout()
                await keycloak?.logout()
            }}
        />
    </Space>
}