import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { ChangeReturnTimeMechanicRequest, OfflineRequest } from './types'

export const mechanicOfflineRequestsApi = createApi({
    reducerPath: 'mechanicOfflineRequestsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getRequests: builder.query<OfflineRequest[], void>({
            query: () => '/mobile/mechanic/request/incoming',
        }),
        approveRequest: builder.mutation<any, String>({
            query: (id) => ({
                url: `/mobile/v2/mechanic/request/goOutOfService/${id}/approve`,
                method: 'POST',
                body: {}
            }),
        }),
        declineRequest: builder.mutation<any, String>({
            query: (id) => ({
                url: `/mobile/mechanic/request/${id}/decline`,
                method: 'POST',
                body: {}
            }),
        }),
        changeReturnTime: builder.mutation<any, [string, ChangeReturnTimeMechanicRequest]>({
            query: (params) => ({
                url: `/mobile/v2/mechanic/request/goOutOfService/${params[0]}/approve `,
                method: 'POST',
                body: params[1],
            }),
        }),
    }),
  })
  
  export const {
    useGetRequestsQuery,
    useApproveRequestMutation,
    useDeclineRequestMutation,
    useChangeReturnTimeMutation,
} = mechanicOfflineRequestsApi