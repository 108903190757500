import { SelectedDurationValue } from "../components/SelectDuration/types";


export function CalculateDurationFromDates({ dateFrom, dateTo }: { dateFrom: Date, dateTo: Date }): SelectedDurationValue {
    const milliDiff: number = dateTo.getTime() - dateFrom.getTime()

    const totalSeconds = Math.floor(milliDiff / 1000)
    const totalMinutes = Math.floor(totalSeconds / 60)
    const totalHours = Math.floor(totalMinutes / 60)
    // const remSeconds = totalSeconds % 60;
    const remMinutes = totalMinutes % 60

    return {
        drurationHours: totalHours,
        drurationMinutes: remMinutes,
    }
}

export function CalculateDurationFromMinutes({ minutes }: { minutes: number }): SelectedDurationValue {
    const totalHours = Math.floor(minutes / 60)
    const remMinutes = minutes % 60

    return {
        drurationHours: totalHours,
        drurationMinutes: remMinutes,
    }
}