import { FC } from "react";
import { Space } from "../../../../../components/Space";
import { Text } from '@consta/uikit/Text';
import { format, parseISO } from "date-fns";
import ruLocale from 'date-fns/locale/ru';
import { DriverPassengerRequest } from "../../types";
import { RequestStateLabel } from "./RequestStateLabel";
import { useDispatch } from "react-redux";
import { updateSelectedRequest } from "../../../slice";


interface Props {
    item: DriverPassengerRequest
}

export const ScheduleRequestItem: FC<Props> = ({ item }) => {
    const dispatch = useDispatch()

    return <div>
        <div onClick={() => {
            dispatch(updateSelectedRequest(item))
        }}>
            <Space
                direction="vertical"
                size="2xs"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Space>
                    <Text view="secondary" weight="regular" size="s">№ {item.reqNum}</Text>
                    <RequestStateLabel state={item.state} />
                </Space>
                <Text view="primary" weight="bold" size="m">{item.name}</Text>
                <Text view="secondary" size="s">{format(parseISO(item.beginDate), "d MMM HH:mm", { locale: ruLocale })} - {format(parseISO(item.endDate), "d MMM HH:mm", { locale: ruLocale })}</Text>
            </Space>
        </div>
    </div>
}   