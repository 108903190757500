import { useRef, useState } from "react";
import { TextField } from "@consta/uikit/TextField";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { Button } from "@consta/uikit/Button";
import { IconAdd } from '@consta/icons/IconAdd';
import { IconRemove } from '@consta/icons/IconRemove';
import { Text } from '@consta/uikit/Text';
import { Space } from "../../Space";
import { BottomSheetTitleHeader } from "../../BottomSheetTitleHeader";
import { Modal } from '@consta/uikit/Modal';
import { MaterialsList } from "./MaterialsList";


export const SelectMaterialController = ({
    disabled,
    field,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    index,
    remove,
    ...fieldProps
}: any) => {

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const refBottomSheet = useRef<SheetRef>()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { onChange, onBlur, value, ref } = field

    return (
        <div key={field.id}>
            <Space>
                <TextField
                    disabled={disabled}
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    style={{ width: '100%' }}
                    {...fieldProps}
                    onBlur={onBlur}
                    ref={ref}
                    placeholder="Материал"
                    type="textarea"
                    readOnly
                    value={value?.material && `${value.material.title} (${value.material?.unitOfMeasure})`}
                />
                <Space size="2xs">
                    <Button
                        disabled={disabled}
                        iconRight={IconRemove}
                        onlyIcon
                        view="ghost"
                        size="xs"
                        onClick={() => {
                            if (value.count > 1) {
                                onChange({
                                    material: value.material,
                                    count: --value.count,
                                })
                            } else {
                                setIsModalOpen(true)
                            }
                        }}
                    />
                    <TextField
                        disabled={disabled || !value.material}
                        style={{ width: 50 }}
                        onBlur={onBlur}
                        onChange={(event) => {
                            onChange({
                                material: value.material,
                                count: event.value,
                            })
                        }}
                        type="number"
                        value={value.count}
                        incrementButtons={false}
                    />
                    <Button
                        iconRight={IconAdd}
                        disabled={disabled || !value.material}
                        onlyIcon
                        view="ghost"
                        size="xs"
                        onClick={() => {
                            onChange({
                                material: value.material,
                                count: ++value.count
                            })
                        }}
                    />
                </Space>

            </Space>
            <Sheet
                ref={refBottomSheet}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                snapPoints={[window.innerHeight - 65, 0]}
                detent="full-height"
                initialSnap={0}
            >
                <Sheet.Container>
                    <Sheet.Header>
                        <Theme preset={presetGpnDefault}>
                            <BottomSheetTitleHeader
                                title='Выбор материала'
                                onClose={() => {
                                    refBottomSheet.current?.snapTo(1)
                                }}
                            />
                        </Theme>
                    </Sheet.Header>
                    <Sheet.Content disableDrag={true}>
                        <Sheet.Scroller draggableAt="top">
                            <Theme preset={presetGpnDefault} style={{ paddingBottom: 80 }}>
                                <MaterialsList
                                    onSelect={(item) => {
                                        onChange({
                                            material: item,
                                            count: value.count
                                        })
                                        setIsDialogOpen(false)
                                    }}
                                />
                            </Theme>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>

            <Modal
                isOpen={isModalOpen}
                hasOverlay
                onClickOutside={() => setIsModalOpen(false)}
                onEsc={() => setIsModalOpen(false)}
                style={{
                    zIndex: 100,
                }}
            >   <div style={{ padding: 'var(--space-s)' }}>
                    <Text as="p" size="m" view="secondary" lineHeight="m">Удалить материал из списка?</Text>
                    <Space>
                        <div>
                            <Button
                                size="m"
                                view="primary"
                                label="Да"
                                width="default"
                                onClick={() => {
                                    setIsModalOpen(false)
                                    remove(index)
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                size="m"
                                view="ghost"
                                label="Отмена"
                                width="default"
                                onClick={() => setIsModalOpen(false)}
                            />
                        </div>
                    </Space>
                </div>
            </Modal>
        </div>
    );
}