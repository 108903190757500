// export class UserAccessDeniedError extends Error {  
//     constructor(message: string) {
//       super(message);

import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

//       Object.setPrototypeOf(this, UserAccessDeniedError.prototype);
//     }

//     getErrorMessage() {
//       return this.message;
//     }
//   }

export const UserNoRolesError : FetchBaseQueryError = {
    status: 'CUSTOM_ERROR',
    error: 'Пользователю не назначено ролей',
}

export const UserManyRolesError : FetchBaseQueryError = {
    status: 'CUSTOM_ERROR',
    error: 'Пользователю назначено больше 1 роли'
}