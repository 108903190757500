import { FC } from "react"
import { Space } from "../../../../../components/Space"
import { Button } from "@consta/uikit/Button"
import { Text } from '@consta/uikit/Text';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox } from "@consta/uikit/Checkbox";
import { SelectDateTime } from "../../../../../components/SelectDateTime";

interface Props {
    returnToWorkDate: Date | undefined
    onCancel: () => void
    onApprove: (returnToWorkDate?: Date | undefined) => void
}

type FormData = {
    unknownPeriod: boolean
    returnToWorkDate: Date | undefined
}

export const MechanicApproveOfflineRequest: FC<Props> = ({ onCancel: onClose, onApprove, returnToWorkDate }) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm<FormData>(
        {
            defaultValues: {
                unknownPeriod: !!!returnToWorkDate,
                returnToWorkDate: returnToWorkDate,
            }
        }
    )

    const unknownPeriod = watch('unknownPeriod')

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        onApprove(unknownPeriod ? undefined :  data.returnToWorkDate)
    }

    return <form
        onSubmit={handleSubmit(onSubmit)}
    >
        <div style={{ padding: 'var(--space-s)' }}>
            <Text as="p" size="l" view="primary" lineHeight="m" weight="semibold">Подтверждение заявки</Text>

            <Space direction="vertical">

                <Controller
                    name="unknownPeriod"
                    control={control}
                    render={({ field }) => <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(value) => field.onChange(value.e)}
                        label="На неопределенный срок"
                    />}
                />

                {!unknownPeriod ? <SelectDateTime
                    label='Дата возвращения'
                    style={{ zIndex: 101 }}
                    name='returnToWorkDate'
                    control={control}
                    rules={{
                        required: !unknownPeriod
                    }}
                /> : <></>
                }

                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            type="submit"
                            disabled={!isValid}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => onClose()}
                        />
                    </div>
                </Space>
            </Space>
        </div>
    </form>
}