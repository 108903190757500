export interface OfflineRequest {
    id:               string
    type:             OfflineRequestType
    status:           OfflineRequestStatus
    equipment:        Equipment
    createdAt:        string
    photos:           any[]
    returnToWorkDate: string
    comment:          string
    reason:           string
}

interface Equipment {
    name:              string
    registrationPlate: string
}

export interface ChangeReturnTimeMechanicRequest {
    returnToWorkTime: string
}

export enum OfflineRequestType {
    GO_OUT_OF_SERVICE = "GO_OUT_OF_SERVICE",
    CANCEL_ASSIGNMENT = "CANCEL_ASSIGNMENT",
    DELAY_ASSIGNMENT = "DELAY_ASSIGNMENT",
}

export enum OfflineRequestStatus {
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
    CREATED = "CREATED",
}
