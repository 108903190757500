import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { EquipmentPosition } from '../../../components/Map/types'
import { DriverPassengerScheduleData } from './types'

export const driverPassengerScheduleApi = createApi({
    reducerPath: 'driverPassengerScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getScheduleData: builder.query<DriverPassengerScheduleData, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const scheduleResponse = await fetchWithBQ('/mobile/v2/driver/JobScheduleRequest/passenger')
                if (scheduleResponse.error) {
                    if (scheduleResponse.meta?.response?.status == 409
                        || scheduleResponse.meta?.response?.status == 451
                        || scheduleResponse.meta?.response?.status == 423) {
                        return {
                            error: {
                                status: scheduleResponse.meta?.response?.status,
                                data: scheduleResponse.error.data
                            }
                        }
                    }
                    return { error: scheduleResponse.error }
                }

                const shiftResponse = await fetchWithBQ('/mobile/mdm/CurrentWorkShift')
                if (shiftResponse.error) {
                    return { error: shiftResponse.error }
                }

                const slotResponse = await fetchWithBQ('/mobile/v2/driver/currentSlot')
                if (slotResponse.error) {
                    if (slotResponse.meta?.response?.status == 409
                        || slotResponse.meta?.response?.status == 451
                        || slotResponse.meta?.response?.status == 423) {
                        return {
                            error: {
                                status: slotResponse.meta?.response?.status,
                                data: slotResponse.error.data
                            }
                        }
                    }
                    return { error: slotResponse.error }
                }

                const result = {
                    schedule: scheduleResponse.data,
                    slot: slotResponse.data,
                    shift: shiftResponse.data
                } as DriverPassengerScheduleData

                return { data: result }
            }
        }),
        getEquipmentPosition: builder.query<EquipmentPosition, void>({
            query: () => '/mobile/driver/equipment/lastPosition',
        }),
        startWork: builder.mutation<void, undefined>({
            query: () => ({
                url: '/mobile/v2/driver/tasks/startWork',
                method: 'POST',
            }),
        }),
        endWork: builder.mutation<void, undefined>({
            query: () => ({
                url: '/mobile/v2/driver/tasks/endWork',
                method: 'POST',
            }),
        }),
    }),
})

export const {
    useGetScheduleDataQuery,
    useGetEquipmentPositionQuery,
    useStartWorkMutation,
    useEndWorkMutation,
} = driverPassengerScheduleApi