import { FC } from "react";
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { Button } from "@consta/uikit/Button";
import { useDispatch } from "react-redux";
import { updateForcedUnbindDialog, updateHasApprovedDeviceId } from "../slice";
import { StorageKeys } from "../../../utils/storage-keys";


interface Props {
    title: string,
    isOpen: boolean
}

export const DriverDeviceUnbindDialog: FC<Props> = ({ title, isOpen }) => {
    const dispatch = useDispatch()

    return <Modal
        isOpen={isOpen}
        hasOverlay
        style={{
            zIndex: 100,
        }}
    >   <div style={{ padding: 'var(--space-s)' }}>
            <Text as="p" size="l" view="primary" lineHeight="m">{title}</Text>
            <div style={{ textAlign: 'center', paddingTop: 10 }}>
                <Button
                    size="l"
                    view="primary"
                    label="Отправить заявку"
                    width="default"
                    onClick={() => {
                        localStorage.removeItem(StorageKeys.deviceId)
                        dispatch(updateHasApprovedDeviceId(false))
                        dispatch(updateForcedUnbindDialog(true))
                    }}
                />
            </div>
        </div>
    </Modal>
}