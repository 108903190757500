// import type { Nullable } from '@/types/global';
import Keycloak from 'keycloak-js';
import { getKeycloakConfig } from './user/services';
import { StorageKeys } from './utils/storage-keys';

let keycloak: Keycloak | null = null;

export const createKeycloak = async () => {
  if (!keycloak) {
    let keycloakConfig : Record<string, string>
    const configCache = sessionStorage.getItem(StorageKeys.keycloakConfig)

    if (configCache) {
      keycloakConfig = JSON.parse(configCache)
    } else {
      keycloakConfig = await getKeycloakConfig();
      sessionStorage.setItem(StorageKeys.keycloakConfig, JSON.stringify(keycloakConfig))
    }

    keycloak = new Keycloak({
      url: `${keycloakConfig.keyCloakBaseUrl}/`,
      realm: keycloakConfig.keyCloakRealm,
      clientId: keycloakConfig.keyCloakPublicClientId,
    });

    keycloak!.onTokenExpired = async () => {
      await keycloak!.updateToken(30);
    };

    await keycloak!.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    });
  }
};

export { keycloak };