import { ru } from "date-fns/locale";
import { MdmWorkShift } from "../mdm/types";
import { isAfter, isBefore, parseISO, format, addHours } from 'date-fns'

export interface ShiftDescrption {
    title: string
    period: string
}

export function GetShiftPeriodType({ workShift, date }: { workShift: MdmWorkShift, date: Date }): ShiftType {
    let result: ShiftType
    if (workShift == null || date == null) {
        result = ShiftType.unknown
    } else {
        if (isBefore(date, parseISO(workShift.startTime))) {
            result = ShiftType.previous
        } else if (isBefore(date, parseISO(workShift.endTime))) {
            result = ShiftType.current
        } else {
            result = ShiftType.next
        }
    }
    return result
}

export function GetShiftPeriodDescription({ workShift, type }: { workShift: MdmWorkShift, type: ShiftType }): ShiftDescrption {
    let result: ShiftDescrption
    result = { title: "Предыдущая смена", period: "Период" }
    switch (type) {
        case ShiftType.unknown:
            result = { title: "Описание смены не найдено", period: "-:-" }
            break;
        case ShiftType.previous:
            var date = addHours(parseISO(workShift.startTime), -12)
            result = { title: "Предыдущая смена", period: `${format(date, "dd MMM HH:mm", {locale: ru})} - ${format(parseISO(workShift.startTime), "dd MMM HH:mm",  {locale: ru })}` }
            break;
        case ShiftType.current:
            result = { title: "Текущая смена", period: `${format(parseISO(workShift.startTime), "dd MMM HH:mm", { locale: ru })} - ${format(parseISO(workShift.endTime), "dd MMM HH:mm", {locale: ru})}` }
            break;
        case ShiftType.next:
            var date = addHours(parseISO(workShift.endTime), 12)
            result = { title: "Следующая смена", period: `${format(parseISO(workShift.endTime), "dd MMM HH:mm", {locale: ru})} - ${format(date, "dd MMM HH:mm", {locale: ru})}` }
            break;
    }
    return result
}

export enum ShiftType {
    unknown = "unknown",
    previous = "previous",
    current = "current",
    next = "next",
}

export function CalculateRequestPriority({workShift, date} : {workShift: MdmWorkShift | undefined, date: Date}): number {
    if (!workShift) return 50 

    const shiftType = GetShiftPeriodType({workShift, date})

    if (shiftType == ShiftType.next) {
        return 50
    } else {
        return 0
    }
}