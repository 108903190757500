import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { EditMasterJobRequest, NewJobRequest, UpdateJobRequest } from './types'
import { MasterRequest } from '../../home/types'
import { parseISO } from 'date-fns'
import { MdmMaterial } from '../../../../mdm/types'

export const masterJobRequestApi = createApi({
    reducerPath: 'masterJobRequestApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createJobRequest: builder.mutation<undefined, NewJobRequest>({
            query: (request) => ({
                url: '/mobile/v2/master/JobScheduleRequest',
                method: 'POST',
                body: request
            }),
        }),
        updateJobRequest: builder.mutation<undefined, UpdateJobRequest>({
            query: (request) => ({
                url: `/mobile/v2/master/JobScheduleRequest/${request.id}`,
                method: 'PUT',
                body: request.body
            }),
        }),
        getJobRequestDetails: builder.query<EditMasterJobRequest, string | undefined>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const detailsResponse = await fetchWithBQ(`/mobile/v2/master/JobScheduleRequest/${_arg}`)
                if (detailsResponse.error) {
                    return { error: detailsResponse.error }
                }
                const detailsData = detailsResponse.data as MasterRequest

                const materialsResponse = await fetchWithBQ('/mobile/mdm/materials')
                if (materialsResponse.error) {
                    return { error: materialsResponse.error }
                }
                const materialsData = materialsResponse.data as MdmMaterial[]

                const result = {
                    state: detailsData.state,
                    jobId: detailsData.jobs.at(0)?.id,
                    dateTimeOf: parseISO(detailsData.beginDate),
                    dateTimeTo: parseISO(detailsData.endDate),
                    equipmentTypes: detailsData.jobs.at(0)?.equipmentTypeParams.map((equipmentType) => ({
                        equipmentType: {
                            id: equipmentType.equipmentTypeId,
                            title: equipmentType.equipmentTitle
                        },
                        count: equipmentType.count
                    })),
                    materials: detailsData.jobs.at(0)?.materialRequirements.map((material) => ({
                        material: materialsData.find(element => element.id == material.materialId),
                        count: material.qty
                    })),
                    workPlace: {
                        oilField: {
                            title: detailsData.jobs.at(0)?.wellPadGroupTitle
                        },
                        cluster: {
                            id: detailsData.jobs.at(0)?.poiId,
                            title: detailsData.jobs.at(0)?.wellPadTitle
                        },
                        well: detailsData.jobs.at(0)?.wellId != "00000000-0000-0000-0000-000000000000" ? {
                            id: detailsData.jobs.at(0)?.wellId,
                            title: detailsData.jobs.at(0)?.wellTitle
                        } : undefined
                    },
                    jobTask: {
                        block: {
                            title: detailsData.jobs.at(0)?.stepTitle
                        }
                    }
                } as EditMasterJobRequest

                return { data: result }
            }
        }),
    }),
})

export const {
    useCreateJobRequestMutation,
    useGetJobRequestDetailsQuery,
    useUpdateJobRequestMutation,
} = masterJobRequestApi