import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Equipment } from "./home/types";

export interface MechanicState {
    selectedEquipment: Equipment | null
}

const initialState: MechanicState = {
    selectedEquipment: null,
}

export const mechanicSlice = createSlice({
    name: 'mechanic',
    initialState,
    reducers: {
        updateEquipment: (state, action: PayloadAction<Equipment | null>) => {
            state.selectedEquipment = action.payload
        },
    },
})

export const { updateEquipment } = mechanicSlice.actions

export default mechanicSlice.reducer