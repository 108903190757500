import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconRevert } from '@consta/icons/IconRevert';
import { FC } from 'react';
import { Loader } from '@consta/uikit/Loader';

interface Props {
    refetch: () => void
    isFetching: boolean
}

export const ScheduleHeader: FC<Props> = ({ refetch, isFetching }) => {
    return <>
        <div style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
            // paddingTop: 'var(--space-l)',
            paddingBottom: 'var(--space-s)',
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }}>
            <Text
                view="primary"
                weight="bold"
                size='xl'
            >Лист заданий</Text>
            <Button
                label="Обновить"
                view="ghost"
                size='s'
                iconLeft={IconRevert}
                onlyIcon
                onClick={refetch}
            />
        </div>
        {isFetching ? <Loader size="s" style={{ marginBottom: 'var(--space-s)' }} /> : <div style={{ height: 'var(--space-s)' }} />}
    </>
}