import { FC, Fragment, useRef } from "react"
import { Equipment } from "../types"
import { EquipmentState } from "../../../../components/EquipmentStateLabel/types"
import { CollapseGroup, DefaultItem } from "@consta/uikit/CollapseGroup"
import { EquipmentItem } from "./EquipmentItem"
import { Divider } from "../../../../components/Divider"
import { EquipmentGroup } from "./EquipmentGroup"
import { EmptyDataPlaceholder } from "../../../../components/EmptyDataPlaceholder"
import styles from '../styles.module.css';


interface Props {
    equipments: Equipment[]
}

export const EquipmentList: FC<Props> = ({ equipments }) => {
    const equipmentAssigned = equipments!.filter((equipment) => equipment.hasPlanned
        || [EquipmentState.STATE_LOADING, EquipmentState.STATE_WORK_IN_PROGRESS, EquipmentState.STATE_ON_ROUTE].includes(equipment.state))
    
    const equipmentOutOfService = equipments!.filter((equipment) => equipment.state === EquipmentState.STATE_OUT_OF_SERVICE)
    
    const equipmentFree = equipments!.filter((equipment) => !equipment.hasPlanned
        && [EquipmentState.STATE_WAITING, EquipmentState.STATE_ARRIVAL_UNCONFIRMED, EquipmentState.STATE_PAUSED].includes(equipment.state))
    
    const equipmentReserve = equipments!.filter((equipment) => equipment.state == EquipmentState.STATE_RESERVE)

    const equipmentGroups: DefaultItem[] = [
        {
            label: <EquipmentGroup title="Назначена на работы" count={equipmentAssigned.length} />,
            content: <div>
                {equipmentAssigned.map((equipment, index) => {
                    return <Fragment key={equipment.equipmentId}>
                        {index == 0 && <div style={{ height: 'var(--space-m)' }} />}
                        <EquipmentItem
                            item={equipment}
                        />
                        {index !== equipmentAssigned.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                        {index == equipmentAssigned.length - 1 && <div style={{ height: 'var(--space-m)' }} />}
                    </Fragment>
                })}
            </div>
        },
        {
            label: <EquipmentGroup title="Свободна" count={equipmentFree.length} />,
            content: <div>
                {equipmentFree.map((equipment, index) => {
                    return <Fragment key={equipment.equipmentId}>
                        {index == 0 && <div style={{ height: 'var(--space-m)' }} />}
                        <EquipmentItem
                            item={equipment}
                        />
                        {index !== equipmentFree.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                        {index == equipmentFree.length - 1 && <div style={{ height: 'var(--space-m)' }} />}
                    </Fragment>
                })
                }
            </div>
        },
        {
            label: <EquipmentGroup title="Снята с линии" count={equipmentOutOfService.length} />,
            content: <div>
                {equipmentOutOfService.map((equipment, index) => {
                    return <Fragment key={equipment.equipmentId}>
                        {index == 0 && <div style={{ height: 'var(--space-m)' }} />}
                        <EquipmentItem
                            item={equipment}
                        />
                        {index !== equipmentOutOfService.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                        {index == equipmentOutOfService.length - 1 && <div style={{ height: 'var(--space-m)' }} />}
                    </Fragment>
                })
                }
            </div>
        },
        {
            label: <EquipmentGroup title="В резерве" count={equipmentReserve.length} />,
            content: <div>
                {equipmentReserve.map((equipment, index) => {
                    return <Fragment key={equipment.equipmentId}>
                        {index == 0 && <div style={{ height: 'var(--space-m)' }} />}
                        <EquipmentItem
                            item={equipment}
                        />
                        {index !== equipmentReserve.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                        {index == equipmentReserve.length - 1 && <div style={{ height: 'var(--space-m)' }} />}
                    </Fragment>
                })
                }
            </div>
        }
    ]

    return equipments!.length > 0 ?
        < CollapseGroup
            className={styles.collapseGroup}
            divider={true}
            items={equipmentGroups}
            size="l"
            view="ghost"
            iconPosition="right"
        /> : <EmptyDataPlaceholder
            title={"Нет техники"}
            description={"У вас нет доступной техники"}
        />
}