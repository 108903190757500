import { useNavigate } from "react-router"
import { AppBar } from "../../../components/AppBar"
import { useGetEquipmentItemsQuery } from "../../../mdm/services"
import { TextField } from '@consta/uikit/TextField';
import { useEffect, useState } from "react";
import { IconSearchStroked } from '@consta/icons/IconSearchStroked';
import { Space } from "../../../components/Space";
import { SkeletonBrick } from "@consta/uikit/Skeleton";
import { ErrorDataPlaceholder } from "../../../components/ErrorDataPlaceholder";
import { EmptyDataPlaceholder } from "../../../components/EmptyDataPlaceholder";
import { Text } from "@consta/uikit/Text"
import { Divider } from "../../../components/Divider";
import { MdmEquipmentItem } from "../../../mdm/types";
import { Button } from "@consta/uikit/Button";
import { useBindDeviceMutation } from "./services";
import toast, { Toaster } from 'react-hot-toast';


export const MechanicBindDevice = () => {
    const navigate = useNavigate()

    const { data, isLoading, isError } = useGetEquipmentItemsQuery()
    const [sendRequest, { isLoading: sendRequestIsLoading, isError: sendRequestIsError, isSuccess: sendRequestIsSuccess, }] = useBindDeviceMutation()

    const [value, setValue] = useState<string | null>(null);
    const handleChange = ({ value }: { value: string | null }) => {
        setValue(value)
    };

    const [equipment, setEquipment] = useState<MdmEquipmentItem | undefined>(undefined);

    function filteredData() {
        if (data) {
            return data.filter((equipment) => {
                if (!!!value || value === '') {
                    return equipment;
                } else {
                    return equipment.registrationPlateNumber.toLowerCase().includes(value.toLowerCase());
                }
            })
        } else {
            return []
        }
    }

    useEffect(() => {
        if (sendRequestIsError) {
            toast.error('Произошла ошибка. Не удалось привязать технику')  
        }
        if (sendRequestIsSuccess) {
            toast.success('Устройство успешно привязано к технике')
            setEquipment(undefined)
            navigate(-1)
        }
    }, [sendRequestIsError, sendRequestIsSuccess])


    return <div>        
        <AppBar
            style={{ zIndex: 2 }}
            title="Привязать к ТС"
            onBackPress={() => navigate(-1)}
        />

        {(!isError && (!data || isLoading)) &&
            <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}
            >
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
            </Space>
        }

        {isError && <ErrorDataPlaceholder
            title="Произошла ошибка"
            description="Не удалось загрузить список техники"
            style={{ paddingTop: 80 }}
        />}

        {(data && data.length === 0 && !isLoading) && <EmptyDataPlaceholder
            title="Здесь пусто"
            description="У вас нет доступной техники"
            style={{ paddingTop: 80 }}
        />}

        {(data && !isLoading) && <Space
            direction="vertical"

        >
            <div style={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                paddingTop: 80,
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            >
                <TextField
                    width="full"
                    onChange={handleChange}
                    value={value}
                    type="text"
                    placeholder="Поиск"
                    withClearButton
                    leftSide={IconSearchStroked}
                />
            </div>
            <div style={{
                marginBottom: 'var(--space-m)',
                paddingTop: 120,
                paddingBottom: 60,
            }}>
                {filteredData().map((item, index) => {
                    return <div
                        style={{
                            backgroundColor: item == equipment ? '#0078D280' : 'transparent',
                            paddingLeft: 'var(--space-l)',
                            paddingRight: 'var(--space-l)',
                        }}
                        key={item.id}
                        onClick={() => { setEquipment(item) }}
                    >
                        <Text style={{ paddingTop: 'var(--space-s)', paddingBottom: 'var(--space-s)' }} >{item.registrationPlateNumber}</Text>
                        {index !== data.length - 1 && <Divider />}
                    </div>
                })
                }
            </div>

        </Space>}

        <Button
            form="round"
            label="Отправить"
            disabled={!equipment}
            loading={sendRequestIsLoading}
            style={{
                position: "fixed",
                bottom: 'var(--space-l)',
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            onClick={() => sendRequest({
                resourceId: equipment!.id
            })}
        />
    </div>
}