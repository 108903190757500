import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { ArrivalConfirmedRequest, OperationCompleteRequest, EquipmentTelemetry, MasterRequestDetails, MasterRequestResource, MasterRequestStep, StartWorkRequest, TelemetryRequest, CompleteWorkRequest } from './types'
import { parseISO } from 'date-fns'

export const masterRequestDetailsApi = createApi({
    reducerPath: 'masterRequestDetailsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getRequestDetails: builder.query<MasterRequestDetails, string>({
            async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {

                type responseType = {
                    steps: Record<string, MasterRequestStep>
                    resources: Record<string, MasterRequestResource>
                }

                const mainResponse = await fetchWithBQ(`/mobile/v2/master/JobScheduleRequest/${id}/view`)
                if (mainResponse.error) {
                    return { error: mainResponse.error }
                }

                const raw = mainResponse.data as responseType
                
                const result = {
                    steps: Object.keys(raw.steps).map((key) => {
                        const val = raw.steps[key]
                        val.id = key
                        return val
                    }).sort((a, b) => parseISO(a.dateTimeTo).getTime() - parseISO(b.dateTimeOf).getTime()),
                    resources: raw.resources
                } as MasterRequestDetails

                return { data: result}
            }
        }),
        startWork: builder.mutation<void, StartWorkRequest>({
            query: (request) => ({
                url: '/mobile/v2/master/operationStarted',
                method: 'POST',
                body: request,
            }),
        }),
        completeWork: builder.mutation<void, OperationCompleteRequest>({
            query: (request) => ({
                url: '/mobile/v2/master/operationCompleted',
                method: 'POST',
                body: request,
            }),
        }),
        arrivalConfirmed: builder.mutation<void, ArrivalConfirmedRequest>({
            query: (request) => ({
                url: '/mobile/v2/master/arrivalConfirmed',
                method: 'POST',
                body: request,
            }),
        }),
        getTelemetry: builder.mutation<EquipmentTelemetry, TelemetryRequest>({
            query: (request) => ({
                url: `/mobile/getAllTelemetry`,
                method: "POST",
                body: request
            }),
        }),
        workCompleted: builder.mutation<void, CompleteWorkRequest>({
            query: (request) => ({
                url: 'mobile/v2/master/workCompleted',
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const {
    useGetRequestDetailsQuery,
    useStartWorkMutation,
    useCompleteWorkMutation,
    useArrivalConfirmedMutation,
    useGetTelemetryMutation,
    useWorkCompletedMutation,
} = masterRequestDetailsApi