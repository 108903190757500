import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EquipmentState } from "../../components/EquipmentStateLabel/types";
import { StorageKeys } from "../../utils/storage-keys";
import { DriverPassengerRequest, DriverPassengerSlot } from "./home/types";

export interface DriverPassengerState {
    currentSlot: DriverPassengerSlot | undefined
    equipmentState: EquipmentState | undefined
    selectedRequest: DriverPassengerRequest | undefined
    currentRequest: DriverPassengerRequest | undefined
    regNumber: string | undefined
    hasApprovedDeviceId: boolean
    forcedUnbindDialog: boolean
}

const initialState: DriverPassengerState = {
    currentSlot: undefined,
    currentRequest: undefined,
    selectedRequest: undefined,
    equipmentState: undefined,
    regNumber: undefined,
    hasApprovedDeviceId: localStorage.getItem(StorageKeys.deviceId) != null,
    forcedUnbindDialog: false,
}

export const driverPassengerSlice = createSlice({
    name: 'driverPassengerSlice',
    initialState,
    reducers: {
        updateCurrentSlot: (state, action: PayloadAction<DriverPassengerSlot | undefined>) => {
            state.currentSlot = action.payload
        },
        updateEquipmentState: (state, action: PayloadAction<EquipmentState | undefined>) => {
            state.equipmentState = action.payload
        },
        updateRegNumber: (state, action: PayloadAction<string | undefined>) => {
            state.regNumber = action.payload
        },
        updateHasApprovedDeviceId: (state, action: PayloadAction<boolean>) => {
            state.hasApprovedDeviceId = action.payload
        },
        updateForcedUnbindDialog: (state, action: PayloadAction<boolean>) => {
            state.forcedUnbindDialog = action.payload
        },
        updateCurrentRequest: (state, action: PayloadAction<DriverPassengerRequest | undefined>) => {
            state.currentRequest = action.payload
        },
        updateSelectedRequest: (state, action: PayloadAction<DriverPassengerRequest | undefined>) => {
            state.selectedRequest = action.payload
        },
    },
})

export const {
    updateCurrentSlot,
    updateEquipmentState,
    updateRegNumber,
    updateHasApprovedDeviceId,
    updateForcedUnbindDialog,
    updateCurrentRequest,
    updateSelectedRequest,
} = driverPassengerSlice.actions

export default driverPassengerSlice.reducer