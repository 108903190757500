import { FC } from "react"
import { Space } from "../../../../components/Space"
import { Equipment } from "../types"
import { RegisterPlate } from "../../../../components/RegisterPlate"
import { Text } from "@consta/uikit/Text"
import { EquipmentStateLabel } from "../../../../components/EquipmentStateLabel"
import { IconArrowRight } from '@consta/icons/IconArrowRight';
import { useDispatch } from "react-redux"
import { updateEquipment } from "../../slice"

interface Props {
    item: Equipment
}

export const EquipmentItem: FC<Props> = ({ item }) => {
    const dispatch = useDispatch()
    
    return <div
        style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        }}
        onClick={() => dispatch(updateEquipment(item))}
    >
        <Space
            direction="vertical"
            size="s"
            style={{width: "90%" }}
        >
            <div style={{ width: "fit-content" }}>
                <EquipmentStateLabel state={item.state} />
            </div>

            <Space size="xs">
                <RegisterPlate regNumber={item.registrationPlate} />
                <Text truncate view="primary" weight="regular">{item.name}</Text>
            </Space>
        </Space>
        <IconArrowRight view="ghost" />
    </div>


}