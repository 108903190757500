import { useState } from "react";
import { Space } from "../../../../../components/Space";
import { TextField } from "@consta/uikit/TextField";
import { Text } from '@consta/uikit/Text';
import { FieldLabel } from "@consta/uikit/FieldLabel";
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { SelectWorkPlaceDialog } from "../../../../../components/SelectWorkPlace/components/SelectWorkPlaceDialog";
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/icons/IconClose';
import { Collapse } from '@consta/uikit/Collapse';


export const SelectRoutePointController = ({
    disabled,
    field,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    index,
    move,
    ...fieldProps
}: any) => {
    const { onChange, onBlur, value, ref } = field

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [isCollapsed, setIsCollapsed] = useState<boolean>(index == 0);

    return <div key={field.id}>
        {!value.deleted ? <Collapse
            size="s"
            isOpen={isCollapsed}
            onClick={() => setIsCollapsed(!isCollapsed)}
            label={
                <Space spaceBetween>
                    <Text view="primary" size="s" weight="semibold">Точка объезда {index + 1}</Text>
                    {index > 0
                        ? <Button
                            view="ghost"
                            iconLeft={IconClose}
                            onlyIcon
                            size="s"
                            onClick={() => {
                                onChange({
                                    id: value.id,
                                    oilField: value.oilField,
                                    cluster: value.cluster,
                                    well: value.well,
                                    comment: value.value,
                                    drurationMinutes: value.drurationMinutes,
                                    drurationHours: value.drurationHours,
                                    deleted: true,
                                })
                            }}
                        />
                        : <></>}
                </Space>
            }
        >
            <Space direction="vertical" size="m">
                <TextField
                    disabled={disabled}
                    onClick={() => {
                        setIsDialogOpen(true)
                        onChange({
                            id: value.id,
                            oilField: undefined,
                            cluster: undefined,
                            well: undefined,
                            comment: value.value,
                            drurationMinutes: value.drurationMinutes,
                            drurationHours: value.drurationHours,
                        })
                    }}
                    style={{ width: '100%' }}
                    {...fieldProps}
                    onBlur={onBlur}
                    ref={ref}
                    placeholder="Выберите место"
                    required={rules?.required}
                    label='Место'
                    type="textarea"
                    readOnly
                    value={value?.oilField && value?.cluster ? `${value.oilField.title} / ${value.cluster.title} ${value?.well ? '/' : ''} ${value?.well ? value?.well!.title : ''}` : ''}
                />
                <FieldLabel required={rules?.required}>Длительность</FieldLabel>
                <FieldGroup>
                    <TextField
                        disabled={disabled}
                        onBlur={onBlur}
                        type="number"
                        value={value.drurationHours}
                        incrementButtons={false}
                        onChange={(event) => {
                            onChange(
                                {
                                    id: value.id,
                                    oilField: value.oilField,
                                    cluster: value.cluster,
                                    well: value.well,
                                    comment: value.comment,
                                    drurationHours: event.value,
                                    drurationMinutes: value?.drurationMinutes
                                }
                            )
                        }}
                    />
                    <TextField
                        disabled={disabled}
                        style={{ width: 30 }}
                        readOnly
                        value='ч'
                    />
                    <TextField
                        disabled={disabled}
                        onBlur={onBlur}
                        type="number"
                        value={value.drurationMinutes}
                        incrementButtons={false}
                        onChange={(event) => {
                            onChange(
                                {
                                    id: value.id,
                                    oilField: value.oilField,
                                    cluster: value.cluster,
                                    well: value.well,
                                    comment: value.comment,
                                    drurationHours: value?.drurationHours,
                                    drurationMinutes: event.value,
                                }
                            )
                        }}
                    />
                    <TextField
                        disabled={disabled}
                        style={{ width: 55 }}
                        readOnly
                        value='мин'
                    />
                </FieldGroup>
                {!disabled ? <Space>
                    <Text
                        decoration="underline"
                        size="s"
                        onClick={() => {
                            onChange({
                                id: value.id,
                                oilField: value.oilField,
                                cluster: value.cluster,
                                well: value.well,
                                comment: value.comment,
                                drurationMinutes: 30,
                                drurationHours: 0,
                            })
                        }}>Полчаса</Text>
                    <Text
                        decoration="underline"
                        size="s"
                        onClick={() => {
                            onChange({
                                id: value.id,
                                oilField: value.oilField,
                                cluster: value.cluster,
                                well: value.well,
                                comment: value.comment,
                                drurationMinutes: 0,
                                drurationHours: 1,
                            })
                        }}>Час</Text>
                    <Text
                        decoration="underline"
                        size="s"
                        onClick={() => {
                            onChange({
                                id: value.id,
                                oilField: value.oilField,
                                cluster: value.cluster,
                                comment: value.comment,
                                well: value.well,
                                drurationMinutes: 0,
                                drurationHours: 2,
                            })
                        }}>2 часа</Text>
                    <Text
                        decoration="underline"
                        size="s"
                        onClick={() => {
                            onChange({
                                id: value.id,
                                oilField: value.oilField,
                                cluster: value.cluster,
                                well: value.well,
                                comment: value.comment,
                                drurationMinutes: 0,
                                drurationHours: 4,
                            })
                        }}>4 часа</Text>
                </Space> : <></>}

                <TextField
                    disabled={disabled}
                    label="Комментарий"
                    placeholder="Введите комментарий"
                    onChange={(e) => {
                        onChange({
                            id: value.id,
                            oilField: value.oilField,
                            cluster: value.cluster,
                            well: value.well,
                            comment: e.value,
                            drurationMinutes: value.drurationMinutes,
                            drurationHours: value.drurationHours,
                        })
                    }}
                    value={value.comment}
                    type="textarea"
                    rows={3}
                />
                <></>
            </Space>

            <SelectWorkPlaceDialog
                level={2}
                isOpen={isDialogOpen}
                onClose={() => { setIsDialogOpen(false) }}
                onSelect={(workPlace) => {
                    setIsDialogOpen(false)
                    onChange({
                        id: value.id,
                        oilField: workPlace.oilField,
                        cluster: workPlace.cluster,
                        well: workPlace.well,
                        comment: value.value,
                        drurationMinutes: value.drurationMinutes,
                        drurationHours: value.drurationHours,
                    })
                }}
            />
        </Collapse> : <></>}
    </div>
}