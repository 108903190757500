import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "@consta/uikit/Button";
import { Space } from "../Space";
import { FieldLabel } from '@consta/uikit/FieldLabel';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { SelectEquipmentTypeController } from "./components/SelectEquipmentController";

export const SelectEquipmentType = ({
    single = false,
    disabled,
    title,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
}: any) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        rules: {
            minLength: rules.required ? 1 : undefined,
            required: rules.required,
        }
    });

    const context = useFormContext()

    return <Space direction="vertical" size="xs">
        <FieldLabel required={rules?.required} className={cnMixSpace({ mB: 'xs' })}>{title ?? 'Потребность в СПТ'}</FieldLabel>
        <>
            {fields.map((item, index) => (
                <Controller
                    key={item.id}
                    name={`${name}.${index}`}
                    rules={{
                        validate: (field) => field.equipmentType != undefined
                    }}
                    defaultValue={defaultValue}
                    control={control || context.control}
                    shouldUnregister={shouldUnregister}
                    render={({ field }) => SelectEquipmentTypeController({
                        single,
                        disabled,
                        field,
                        rules,
                        index,
                        control,
                        defaultValue,
                        shouldUnregister,
                        remove,
                    })}
                />
            ))}
        </>
        {!single ? <Button
            disabled={disabled}
            label="+ Добавить"
            view="ghost"
            onClick={() => append({
                equipmentType: undefined,
                count: 1
            })} /> : <></>}
    </Space>
}