import { FC } from "react"
import { OfflineRequest, OfflineRequestStatus, OfflineRequestType } from "../types"
import { Space } from "../../../../../components/Space"
import { Text } from "@consta/uikit/Text"
import { format, parseISO } from "date-fns"
import ruLocale from 'date-fns/locale/ru';
import { RegisterPlate } from "../../../../../components/RegisterPlate"
import { Badge } from '@consta/uikit/Badge';
import { Tag } from '@consta/uikit/Tag';
import { TagBasePropGroup } from "@consta/uikit/__internal__/src/components/TagBase/TagBase"


interface Props {
    item: OfflineRequest
    onPress: () => void
}

export const RequestItem: FC<Props> = ({ item, onPress }) => {
    const types: Record<OfflineRequestType, string> = {
        [OfflineRequestType.GO_OUT_OF_SERVICE]: "Сход с линии",
        [OfflineRequestType.CANCEL_ASSIGNMENT]: "Отмена назначения",
        [OfflineRequestType.DELAY_ASSIGNMENT]: "Задержка",
    }

    const groups: Record<OfflineRequestType, TagBasePropGroup> = {
        [OfflineRequestType.GO_OUT_OF_SERVICE]: 1,
        [OfflineRequestType.CANCEL_ASSIGNMENT]: 2,
        [OfflineRequestType.DELAY_ASSIGNMENT]: 3,
    }

    const statusText: Record<OfflineRequestStatus, string> = {
        [OfflineRequestStatus.APPROVED]: "Одобрена",
        [OfflineRequestStatus.DECLINED]: "Отменена",
        [OfflineRequestStatus.CREATED]: "В работе",
    }

    return <div onClick={onPress}>
        <Space
            direction="vertical"
            size="s"
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
            }}
        >
            <Space>
                <Text view="secondary" size="s">
                    {format(parseISO(item.createdAt), "dd MMMM yyyy", { locale: ruLocale })}
                </Text>
                <Badge label={statusText[item.status]} size="s" />
                <Tag
                    style={{ width: "fit-content" }}
                    size="s"
                    mode="info"
                    label={types[item.type]}
                    group={groups[item.type]}
                />
            </Space>
            <Space>
                <RegisterPlate regNumber={item.equipment.registrationPlate} />
                <Text truncate view="primary" weight="regular">{item.equipment.name}</Text>
            </Space>
        </Space>
    </div>
}