import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MasterRequest, RequestOwnerFilter } from "./home/types";

export interface MasterState {
    selectedRequest: MasterRequest | undefined
    selectedEquipment: string | undefined
    teamId: string
    requestOwnerFilter: RequestOwnerFilter
}

const initialState: MasterState = {
    selectedRequest: undefined,
    selectedEquipment: undefined,
    teamId: '',
    requestOwnerFilter: RequestOwnerFilter.MY,
}

export const masterSlice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        updateSelectedRequest: (state, action: PayloadAction<MasterRequest | undefined>) => {
            state.selectedRequest = action.payload
        },
        updateSelectedEquipment: (state, action: PayloadAction<string | undefined>) => {
            state.selectedEquipment = action.payload
        },
        updateTeamId: (state, action: PayloadAction<string>) => {
            state.teamId = action.payload
        },
        updateRequestOwnerFilter: (state, action: PayloadAction<RequestOwnerFilter>) => {
            state.requestOwnerFilter = action.payload
        },
    },
})

export const {
    updateSelectedRequest,
    updateSelectedEquipment,
    updateTeamId,
    updateRequestOwnerFilter,
} = masterSlice.actions

export default masterSlice.reducer