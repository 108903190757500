import { useEffect } from "react"
import { Divider } from "../../../../../components/Divider"
import SliderButton from "../../../../../components/SliderButton"
import { Space } from "../../../../../components/Space"
import { useBoardingStartedMutation } from "../services"
import toast from "react-hot-toast"
import { useGetScheduleDataQuery } from "../../services"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../store"


export const DriverPassengerStateStartBoarding = () => {
    const currentRequest = useSelector((state: RootState) => state.driverPassenger.currentRequest!)

    const [boardingStarted, { isLoading, isError, isSuccess }] = useBoardingStartedMutation()
    const { refetch: refetchSchedule, isFetching } = useGetScheduleDataQuery(undefined, { skip: !isSuccess })

    useEffect(() => {
        if (isError) {
            toast.error('Произошла ошибка. Не удалось начать ожидание')
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            refetchSchedule()
        }
    }, [isSuccess])

    return <Space direction="vertical">

        <div style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
        }}>
            <SliderButton
                loading={isLoading || isFetching}
                text='Начать ожидание'
                color='#f38b00'
                onSuccess={() => boardingStarted({ requestId: currentRequest.id })}
            />
        </div>

        <Divider />

    </Space>
}