import { useSelector } from "react-redux";
import { DriverPassengerStateStartMovement } from "./components/DriverPassengerStateStartMovement";
import { RootState } from "../../../../store";
import { PassengerRequestDetails } from "../../PassengerRequestDetails";
import { mapDriverPassengerState } from "./mapper";

export const DriverPassengerState = () => {
    const currentRequest = useSelector((state: RootState) => state.driverPassenger.currentRequest)
    const currentSlot = useSelector((state: RootState) => state.driverPassenger.currentSlot)

    return currentRequest ? <div>
        <PassengerRequestDetails request={currentRequest} />

        {mapDriverPassengerState(currentSlot, currentRequest)}
    </div > : <></>
}