import { Button } from "@consta/uikit/Button"
import { Space } from "./Space"
import { IconClose } from '@consta/icons/IconClose';
import { FC } from "react";
import { Loader } from '@consta/uikit/Loader';


interface Props {
    onClose?: () => void | undefined,
    isFetching?: boolean
}

export const BottomSheetDefaultHeader: FC<Props> = ({ onClose, isFetching }) => {
    return <div style={{
        textAlign: "center",
        width: '100%',
        marginTop: 'var(--space-xs)',
        marginBottom: 'var(--space-s)',
    }}>
        <div style={{
            display: 'inline-block',
            height: 6,
            width: 36,
            backgroundColor: '#e0e0e0',
            borderRadius: 25,
        }} />

        {isFetching && <Loader size="s" style={{ marginTop: 'var(--space-s)' }} />}

        {onClose && <Button
            style={{
                position: "absolute",
                right: 'var(--space-l)',
                top: 'var(--space-l)',
            }}
            label="Закрыть"
            view="ghost"
            size='s'
            iconLeft={IconClose}
            onlyIcon
            onClick={onClose}
        />}
    </div>
}