import { useSelector } from "react-redux"
import { EquipmentRoute } from "../../../components/EquipmentRoute"
import { Space } from "../../../components/Space"
import { RootState } from "../../../store"
import { Text } from '@consta/uikit/Text';
import { format, parseISO } from "date-fns";
import { Divider } from "../../../components/Divider";
import { IconAlert } from '@consta/icons/IconAlert';
import SliderButton from "../../../components/SliderButton";
import { useLoadingStartedMutation } from "./services";
import { useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useGetScheduleDataQuery } from "../services";


export const DriverStateStartLoadMaterials = () => {
    const currentSlot = useSelector((state: RootState) => state.driver.currentSlot)
    const [loadingStarted, { isLoading: loadingStartedLoading, isError: loadingStartedError, isSuccess: loadingStartedSuccess }] = useLoadingStartedMutation()

    const { refetch: refetchSchedule } = useGetScheduleDataQuery(undefined, { skip: !loadingStartedSuccess })

    useEffect(() => {
        if (loadingStartedError) {
            toast.error('Произошла ошибка. Не удалось начать загрузку материалами')
        }
    }, [loadingStartedError])

    useEffect(() => {
        if (loadingStartedSuccess) {
            refetchSchedule()
        }
    }, [loadingStartedSuccess])

    if (currentSlot) {

        return <>
            <Space
                direction="vertical"
                size="s"
                style={{
                    paddingTop: 10,
                }}
            >
                <Space
                    direction="vertical"
                    size="xs"
                    style={{
                        paddingLeft: 'var(--space-l)',
                        paddingRight: 'var(--space-l)',
                    }}>

                    <Space direction="vertical">
                        <EquipmentRoute
                            titleOf={currentSlot.titleOf}
                            titleTo={currentSlot.titleTo}
                        />
                        <Space size="xs">
                            <Text view="secondary">{format(parseISO(currentSlot.beginDate), "HH:mm")} - {format(parseISO(currentSlot.endDate), "HH:mm")}</Text>
                            {currentSlot.distance ? <Text view="secondary">{currentSlot.distance.toPrecision(2)} км</Text> : <></>}
                        </Space>
                    </Space>

                    <SliderButton
                        text='Начать загрузку'
                        color='#0078d2'
                        loading={loadingStartedLoading}
                        onSuccess={() => loadingStarted({ slotId: currentSlot.id })}
                    />
                </Space>

                <Divider height={6} />
            </Space>
        </>
    } else {
        return <Space
            direction="vertical"
            size="s"
            style={{
                paddingTop: 30,
                alignItems: "center"
            }}>
            <IconAlert view="alert" />
            <Text view="critical">Не найдено текущее назначение</Text>
            <Divider />
        </Space>
    }
}