import { createApi } from '@reduxjs/toolkit/query/react'
import { EquipmentDetails, MechnicPermittedToWorkRequest } from './types'
import { baseQuery } from '../../../../../base-service'

export const mechanicDetailsApi = createApi({
    reducerPath: 'mechanicDetailsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getEquipmentDetails: builder.query<EquipmentDetails, string>({
            query: (id) => `/mobile/mechanic/equipment/${id}/details`,
        }),
        returnToService: builder.mutation<void, MechnicPermittedToWorkRequest>({
            query: (request) => ({
                url: `/mobile/v2/mechanic/permittedToWork`,
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const { useReturnToServiceMutation, useGetEquipmentDetailsQuery } = mechanicDetailsApi