import { Theme, presetGpnDefault } from "@consta/uikit/Theme"
import { FC, useEffect, useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import { AppBarBottomSheet } from "../../../../../components/AppBarBottomSheet"
import { Space } from "../../../../../components/Space"
import { Button } from "@consta/uikit/Button"
import { BindResorcesRequest } from "../types"
import { useDeclineDeviceBindingMutation } from "../services"
import { TextField } from '@consta/uikit/TextField'
import toast, { Toaster } from 'react-hot-toast';


interface Props {
    request: BindResorcesRequest
    onClose: (update?: boolean) => void
}

export const DeclineBindingRequestDialog: FC<Props> = ({ request, onClose }) => {
    const [declineRequest, { isLoading: declineLoading, isError: declineError, isSuccess: declineSuccess }] = useDeclineDeviceBindingMutation()
    const [value, setValue] = useState<string | null>(null);
    const handleChange = ({ value }: { value: string | null }) => setValue(value);

    useEffect(() => {
        if (declineError) {
            toast.error('Произошла ошибка. Не удалось отклонить заявку')
        }
        if (declineSuccess) {
            onClose(true)
        }
    }, [declineError, declineSuccess])

    return <>
        <BottomSheet
            id="bottomSheet"
            open
            snapPoints={({ }) => [320, 320]}
            blocking={true}
            onDismiss={() => onClose()}
        >
            <Theme preset={presetGpnDefault}>
                <div>
                    <AppBarBottomSheet
                        title="Отклонить заявку"
                        onBackPress={onClose}
                    />
                    <Space
                        direction="vertical"
                        size="s"
                        style={{
                            paddingLeft: 'var(--space-l)',
                            paddingRight: 'var(--space-l)',
                            marginTop: 'var(--space-l)',
                        }}>
                        <TextField
                        placeholder="Комментарий"
                        onChange={handleChange}
                        value={value}
                            type="textarea"
                            rows={5}
                        />
                        <Button
                            form="round"
                            loading={declineLoading}
                            label="Отправить"
                            style={{ backgroundColor: '#0078D2' }}
                            onClick={() => declineRequest({
                                resourceBindingId: request.resourceBindingId,
                                comment: value ?? '',
                            })}
                        />
                    </Space>
                </div>
            </Theme>
        </BottomSheet>
    </>
}