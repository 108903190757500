import { Controller, useFormContext } from "react-hook-form"
import { RadioGroup } from '@consta/uikit/RadioGroup';
import { FieldLabel } from '@consta/uikit/FieldLabel';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { OrderTypeValue } from "./types";


const items: OrderTypeValue[] = [
    OrderTypeValue.FREE,
    OrderTypeValue.FIXED,
]

export const SelectOrderType = ({
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    ...fieldProps
}: any) => {
    const context = useFormContext()

    const renderSelectShuttle = ({ field }: any) => {

        const { onChange, value } = field

        return <div>
            <FieldLabel required={rules?.required} className={cnMixSpace({ mB: 'xs' })}>Порядок объезда</FieldLabel>

            <RadioGroup
                value={value}
                items={items}
                getItemLabel={(item: OrderTypeValue) => item == OrderTypeValue.FREE ? 'Свободный' : 'Фиксированный'}
                onChange={(event) => {
                    onChange(event.value)
                }}
                direction="row"
            />
        </div>
    }

    return <Controller
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        control={control || context.control}
        shouldUnregister={shouldUnregister}
        render={renderSelectShuttle}
    />
}