import { FC, useEffect, useRef, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import MapboxGL from 'mapbox-gl';
import type { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { DEFAULT_ZOOM, MAP_STYLE, MAX_ZOOM } from './config';
import { useLazyGetPoiQuery, useLazyGetRoadsQuery } from './services';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { Text } from '@consta/uikit/Text';
import { Modal } from '@consta/uikit/Modal';
import { Space } from '../Space';
import { GeometryPoint } from './types';
import vehicleMarker from '../../assets/vehicle_marker.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Button } from "@consta/uikit/Button";
import { ReactComponent as IconMyLocation } from '../../assets/my_location.svg';
import { createIcon } from '@consta/icons/Icon';

interface Props {
    center?: GeometryPoint
}

export const SsmMap: FC<Props> = ({ center }) => {
    const mapContainerRef: any = useRef<string | HTMLDivElement>('')
    const [map, setMap] = useState<Map | null>(null);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);

    const [selectedEquipmentMarker, setSelectedEquipmentMarker] = useState<MapboxGL.Marker | null>(null)
    const [equipmentMarkers, setEquipmentMarkers] = useState<MapboxGL.Marker[] | null>(null)

    const selectedEquipmentPosition = useSelector((state: RootState) => state.map.selectedEquipmentPosition)
    const equipmentPositions = useSelector((state: RootState) => state.map.equipmentPositions)
    const equipmentRoute = useSelector((state: RootState) => state.map.equipmentRoute)
    const centerPoint = useSelector((state: RootState) => state.map.centerPoint)
    const lastUpdateDate = useSelector((state: RootState) => state.map.lastUpdateDate)
    const manualPoints = useSelector((state: RootState) => state.map.manualPoints)

    const [previousLastUpdateDate, setPreviousLastUpdateDate] = useState<number | undefined>()

    const [fetchRoads, { data: roadsData, isFetching: roadsIsLoading }] = useLazyGetRoadsQuery()
    const [fetchPoi, { data: poiData, isFetching: poiIsLoading }] = useLazyGetPoiQuery()

    useMount(() => {
        fetchRoads(false)
        fetchPoi(false)

        setPreviousLastUpdateDate(lastUpdateDate)

        const mapbox = new MapboxGL.Map({
            container: mapContainerRef.current,
            style: MAP_STYLE,
            center: [center?.coordinates[0] ?? 0, center?.coordinates[1] ?? 0],
            zoom: DEFAULT_ZOOM,
            maxZoom: MAX_ZOOM,
        });


        mapbox.once('styledata', () => {
            mapbox.dragRotate.disable();
            mapbox.touchZoomRotate.disableRotation();
            mapbox.addControl(new MapboxGL.NavigationControl({ showCompass: false }), 'top-right');
        })
        mapbox.on('load', () => {
            setMap(mapbox)
            setMapLoaded(true)
        })
        mapbox.on('remove', () => {
            setMapLoaded(false)
        })
    })

    useUnmount(() => {
        setMapLoaded(false)
        map?.remove()
        setMap(null)
    })

    useEffect(() => {
        if (lastUpdateDate != previousLastUpdateDate) {
            fetchRoads(true)
            fetchPoi(true)
        }
    }, [lastUpdateDate])

    useEffect(() => {
        if (center && map) {
            map!.setCenter([center.coordinates[0], center.coordinates[1]])
        }
    }, [center])

    useEffect(() => {
        if (map && mapLoaded && roadsData && poiData) {
            if (map.getLayer('geoData-point')) map.removeLayer('geoData-point')
            if (map.getLayer('geoData-symbol')) map.removeLayer('geoData-symbol')

            if (map.getSource('geoData-point')) map.removeSource('geoData-point')
            if (map.getSource('geoData-symbol')) map.removeSource('geoData-symbol')

            if (map.getLayer('roadArcs')) map.removeLayer('roadArcs')
            if (map.getLayer('roadArcs_2')) map.removeLayer('roadArcs_2')

            if (map.getSource('roadArcs')) map.removeSource('roadArcs')
            if (map.getSource('roadArcs_2')) map.removeSource('roadArcs_2')

            map.addLayer({
                id: 'roadArcs',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: roadsData
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#C8A38E',
                    'line-width': 6,
                    'line-opacity': 1,
                },
            })
            map.addLayer({
                id: 'roadArcs_2',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: roadsData
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#F5D5C3',
                    'line-width': 4,
                    'line-opacity': 1,
                },
            })

            map.addLayer({
                id: 'geoData-point',
                type: 'symbol',
                source: {
                    type: 'geojson',
                    data: poiData
                },
                minzoom: 7.8,
                layout: {
                    'text-field': '•',
                    'text-font': ['Inter-SemiBold'],
                    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 7, 12, 7 * 3, 17, 7 * 6],
                    'symbol-z-order': 'source',
                    'text-allow-overlap': true,
                },
                paint: {
                    'icon-color': '#ccc',
                    'text-color': '#003a8c',
                    'text-halo-color': '#FFF',
                    'text-halo-width': 1,
                },
            })
            map.addLayer({
                id: 'geoData-symbol',
                type: 'symbol',
                source: {
                    type: 'geojson',
                    data: poiData
                },
                layout: {
                    'text-field': '{title}',
                    'text-font': ['Inter-SemiBold'],
                    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 12, 12, 12 * 2, 17, 12 * 5],
                    'symbol-z-order': 'source',
                    'text-anchor': 'top',
                    'text-offset': [0, 0.3],
                },
                paint: {
                    'icon-color': '#ccc',
                    'text-color': '#003a8c',
                    'text-halo-color': '#FFF',
                    'text-halo-width': 1,
                },
            });
        }
    }, [map, mapLoaded, roadsData, poiData])

    useEffect(() => {
        if (map && mapLoaded && manualPoints) {
            if (map.getSource('geoData-manual-point')) map.removeSource('geoData-manual-point')
            if (map.getSource('geoData-manual-symbol')) map.removeSource('geoData-manual-symbol')

            if (map.getLayer('geoData-manual-point')) map.removeLayer('geoData-manual-point')
            if (map.getLayer('geoData-manual-symbol')) map.removeLayer('geoData-manual-symbol')

            map.addLayer({
                id: 'geoData-manual-point',
                type: 'symbol',
                source: {
                    type: 'geojson',
                    data: manualPoints
                },
                minzoom: 7.8,
                layout: {
                    'text-field': '•',
                    'text-font': ['Inter-SemiBold'],
                    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 7, 12, 7 * 3, 17, 7 * 6],
                    'symbol-z-order': 'source',
                    'text-allow-overlap': true,
                },
                paint: {
                    'icon-color': '#ccc',
                    'text-color': '#003a8c',
                    'text-halo-color': '#FFF',
                    'text-halo-width': 1,
                },
            })
            map.addLayer({
                id: 'geoData-manual-symbol',
                type: 'symbol',
                source: {
                    type: 'geojson',
                    data: manualPoints
                },
                layout: {
                    'text-field': '{title}',
                    'text-font': ['Inter-SemiBold'],
                    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 12, 12, 12 * 2, 17, 12 * 5],
                    'symbol-z-order': 'source',
                    'text-anchor': 'top',
                    'text-offset': [0, 0.3],
                },
                paint: {
                    'icon-color': '#ccc',
                    'text-color': '#003a8c',
                    'text-halo-color': '#FFF',
                    'text-halo-width': 1,
                },
            });
        }
    }, [manualPoints])

    useEffect(() => {
        if (equipmentRoute && map && mapLoaded) {
            if (map.getLayer('equipment_route')) map.removeLayer('equipment_route')
            if (map.getSource('equipment_route')) map.removeSource('equipment_route')

            map.addLayer({
                id: 'equipment_route',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            coordinates: equipmentRoute.coordinates,
                            type: 'LineString',
                        },
                        properties: {
                            name: 'equipment_route'
                        }
                    }
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#4db8ff',
                    'line-width': 4,
                    'line-opacity': 1,
                },
            })
        }
    }, [map, mapLoaded, equipmentRoute])

    useEffect(() => {
        if (map && mapLoaded && equipmentPositions) {
            var markers: MapboxGL.Marker[] = []

            equipmentPositions.map((element, index) => {
                equipmentMarkers?.at(index)?.remove()

                const markerLabelElement = document.createElement('div')
                markerLabelElement.textContent = element.regNumber
                markerLabelElement.style.fontSize = '18px'
                markerLabelElement.style.fontWeight = '900'
                markerLabelElement.style.color = 'gray'

                const equipmentMarkerElement = document.createElement('div');
                equipmentMarkerElement.id = element.equipmentId;
                equipmentMarkerElement.style.backgroundImage = `url(${vehicleMarker})`;
                equipmentMarkerElement.style.width = '24px';
                equipmentMarkerElement.style.height = '24px';
                equipmentMarkerElement.style.backgroundSize = '100% 100%';
                equipmentMarkerElement.style.backgroundRepeat = 'no-repeat';

                const el = document.createElement('div')
                el.style.justifyContent = 'center'
                el.appendChild(equipmentMarkerElement)
                el.appendChild(markerLabelElement)

                const equipmentMarker = new MapboxGL.Marker(el as any)
                    .setLngLat([element.geoX, element.geoY])
                    .addTo(map!);
                markers.push(equipmentMarker)

                if (markers.length == 1) {
                    map.setCenter([element.geoX, element.geoY])
                }
            })
            setEquipmentMarkers(markers)
        }

        if (map && mapLoaded && equipmentPositions.length == 0) {
            equipmentMarkers?.forEach((marker) => marker.remove())
            setEquipmentMarkers(null)
        }
    }, [equipmentPositions, map])

    useEffect(() => {
        if (selectedEquipmentPosition && map && !selectedEquipmentMarker) {
            {
                if (equipmentPositions.length == 0) {
                    const markerElement = document.createElement('div');
                    markerElement.id = selectedEquipmentPosition.equipmentId;
                    markerElement.style.backgroundImage = `url(${vehicleMarker})`;
                    markerElement.style.width = '24px';
                    markerElement.style.height = '24px';
                    markerElement.style.backgroundSize = '100% 100%';
                    markerElement.style.backgroundRepeat = 'no-repeat';

                    // const markerElement = ReactDOMServer.renderToStaticMarkup(<MapEquipmentMarker position={selectedEquipmentPosition}/>) 

                    const marker = new MapboxGL.Marker(markerElement)
                        .setLngLat([selectedEquipmentPosition.geoX, selectedEquipmentPosition.geoY])
                        .addTo(map!);
                    setSelectedEquipmentMarker(marker)

                    map.setCenter([selectedEquipmentPosition.geoX, selectedEquipmentPosition.geoY])
                } else {
                    map.setCenter([selectedEquipmentPosition.geoX, selectedEquipmentPosition.geoY])
                }
            }
        }
        if (!selectedEquipmentPosition && map && selectedEquipmentMarker) {
            selectedEquipmentMarker.remove()
            setSelectedEquipmentMarker(null)
        }
    }, [selectedEquipmentPosition, map, equipmentPositions])

    useEffect(() => {
        if (map && centerPoint) {
            map.setCenter(centerPoint)
        }
    }, [centerPoint])




    // if (selectedEquipmentPosition && map && !selectedEquipmentMarker) {
    //     const markerElement = document.createElement('div');
    //     markerElement.id = selectedEquipmentPosition.equipmentId;
    //     markerElement.style.backgroundImage = `url(${vehicleMarker})`;
    //     markerElement.style.width = '24px';
    //     markerElement.style.height = '24px';
    //     markerElement.style.backgroundSize = '100% 100%';
    //     markerElement.style.backgroundRepeat = 'no-repeat';

    //     // const markerElement = ReactDOMServer.renderToStaticMarkup(<MapEquipmentMarker position={selectedEquipmentPosition}/>) 

    //     const marker = new MapboxGL.Marker(markerElement)
    //         .setLngLat([selectedEquipmentPosition.geoX, selectedEquipmentPosition.geoY])
    //         .addTo(map!);
    //     setSelectedEquipmentMarker(marker)

    //     map.setCenter([selectedEquipmentPosition.geoX, selectedEquipmentPosition.geoY])
    // }

    // if (!selectedEquipmentPosition && map && selectedEquipmentMarker) {
    //     selectedEquipmentMarker.remove()
    //     setSelectedEquipmentMarker(null)
    // }


    return <>
        <Modal
            position='center'
            isOpen={roadsIsLoading || poiIsLoading}
            hasOverlay
            style={{ zIndex: 2 }}
        >
            <Space direction="vertical" size="s" style={{ padding: 10, alignItems: "center", justifyContent: "center", }}>
                <Text as="p" size="l" view="primary">
                    Загружаются данные карты
                </Text>
                <ProgressSpin size="xl" />
            </Space>

        </Modal>

        <div style={{ height: "100vh", width: "100vw" }}
            ref={mapContainerRef}
        />

        {selectedEquipmentPosition && <Button
            label="Центр"
            view="ghost"
            iconLeft={createIcon({
                name: "IconMyLocation",
                m: (props) => <IconMyLocation {...props} />,
                s: (props) => <IconMyLocation {...props} />,
                xs: (props) => <IconMyLocation {...props} />,
                l: (props) => <IconMyLocation {...props} />,
            })}
            onlyIcon
            onClick={() => map?.setCenter([selectedEquipmentPosition.geoX, selectedEquipmentPosition.geoY])}
            style={{
                position: "absolute",
                right: "10px",
                top: "80px",
                backgroundColor: "#ffffff"
            }}
        />}

        {/* {map && equipmentPositions?.length == 1 && <Button
            label="Центр"
            view="ghost"
            iconLeft={createIcon({
                name: "IconMyLocation",
                m: (props) => <IconMyLocation {...props} />,
                s: (props) => <IconMyLocation {...props} />,
                xs: (props) => <IconMyLocation {...props} />,
                l: (props) => <IconMyLocation {...props} />,
            })}
            onlyIcon
            onClick={() => map?.setCenter([equipmentPositions[0].geoX, equipmentPositions[0].geoY])}
            style={{
                position: "absolute",
                right: "10px",
                top: "80px",
                backgroundColor: "#ffffff"
            }}
        />
        } */}
    </>
}