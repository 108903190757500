import { useEffect, useRef, useState } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { BottomSheetDefaultHeader } from '../../../components/BottomSheetDefaultHeader';
import { SsmMap } from '../../../components/Map';
import { presetGpnDefault, Theme } from '@consta/uikit/Theme';
import { Button } from "@consta/uikit/Button";
import { IconHamburger } from '@consta/icons/IconHamburger';
import { ProfileSideBar } from '../../../components/ProfileSideBar';
import { RegisterPlate } from '../../../components/RegisterPlate';
import { logout } from '../../../user/services';
import { keycloak } from '../../../Keycloak';
import { ScheduleHeader } from './schedule/components/ScheduleHeader';
import { useEndWorkMutation, useGetEquipmentPositionQuery, useGetScheduleDataQuery, useStartWorkMutation } from './services';
import { ScheduleSkeleton } from './schedule/components/ScheduleSkeleton';
import { ErrorDataPlaceholder } from '../../../components/ErrorDataPlaceholder';
import { EmptyDataPlaceholder } from '../../../components/EmptyDataPlaceholder';
import { SchedulePassenger } from './schedule/components/Schedule';
import { PassengerRequestDetails } from '../PassengerRequestDetails';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SliderButton from '../../../components/SliderButton';
import { IconStop } from '@consta/icons/IconStop';
import { Divider } from '../../../components/Divider';
import toast from 'react-hot-toast';
import { updateCurrentRequest, updateCurrentSlot, updateEquipmentState, updateRegNumber, updateSelectedRequest } from '../slice';
import { Space } from '../../../components/Space';
import { EquipmentStateLabel } from '../../../components/EquipmentStateLabel';
import { DriverPassengerState } from './states';
import { useInterval } from 'react-use';
import { updateEquipmentRoute, updateSelectedEquipmentPosition } from '../../../components/Map/slice';
import { DriverDeviceUnbindDialog } from '../../driver/home/DriverDeviceUnbindDialog';
import { useGetNewNoticeListQuery } from '../notifications/services';
import { DriverNotificationView } from '../notifications';
import { swRegistration } from '../../../notifications';


const defaultHeaderSize = 96

export const DriverPassengerHome = () => {
    const sceduleListRef = useRef<SheetRef>();
    const dispatch = useDispatch()
    const selectedRequest = useSelector((state: RootState) => state.driverPassenger.selectedRequest)

    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [showUnbindErrorDialog, setShowUnbindErrorDialog] = useState(false);

    const { data: scheduleData, error: scheduleError, isLoading: scheduleIsLoading, isFetching: scheduleisFetching, refetch: refetchSchedule } = useGetScheduleDataQuery()
    const [startWork, { isLoading: startWorkIsLoading, isError: startWorkIsError, isSuccess: startWorkIsSuccess }] = useStartWorkMutation()
    const [endWork, { isError: endWorkIsError, isSuccess: endWorkIsSuccess }] = useEndWorkMutation()
    const { data: equipmentPositionData, refetch: refetchEquipmentPosition } = useGetEquipmentPositionQuery()
    const { data: notifications, refetch: refetchNotifications } = useGetNewNoticeListQuery()

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            notifications.forEach(element => {
                // show local toast
                toast((t) => (
                    <DriverNotificationView
                        onClose={() => toast.dismiss(t.id)}
                        notification={{
                            notificationId: element.notificationId,
                            text: element.text,
                        }} />
                ), {
                    duration: 30000,
                    id: element.notificationId,
                    className: 'notifictaion-toast',
                })

                // show push on mobile device
                const options = { body: element.text }
                swRegistration?.showNotification('Новое уведомление!', options)
            })

        }
    }, [notifications])

    useEffect(() => {
        if (scheduleData) {
            dispatch(updateCurrentSlot(scheduleData.slot))
            dispatch(updateEquipmentState(scheduleData.slot.resourceState))
            dispatch(updateRegNumber(scheduleData.slot.registrationPlateNumber))
            dispatch(updateCurrentRequest(scheduleData.schedule.requests?.find(element => element.id == scheduleData?.slot?.requestId)))
        }
        if (scheduleError) {
            dispatch(updateCurrentSlot(undefined))
            dispatch(updateEquipmentState(undefined))
            dispatch(updateRegNumber(undefined))
            dispatch(updateCurrentRequest(undefined))
        }
    }, [scheduleData, scheduleError])

    useEffect(() => {
        if (selectedRequest) {
            document.getElementById('scheduleBottomSheet')?.scroll(0, 0)
        }
    }, [selectedRequest])

    useEffect(() => {
        if (startWorkIsError) {
            toast.error('Произошла ошибка. Не удалось начать смену')
        }
        if (startWorkIsSuccess) {
            refetchSchedule()
        }
    }, [startWorkIsError, startWorkIsSuccess])

    useEffect(() => {
        if (endWorkIsError) {
            toast.error('Произошла ошибка. Не удалось завершить смену')
        }
        if (endWorkIsSuccess) {
            setIsProfileOpen(false)
            refetchSchedule()
        }
    }, [endWorkIsError, endWorkIsSuccess])

    useInterval(
        () => {
            refetchSchedule()
            refetchEquipmentPosition()
            refetchNotifications()
        },
        15 * 1000,
    )

    useEffect(() => {
        if (equipmentPositionData) {
            dispatch(updateSelectedEquipmentPosition(equipmentPositionData))
        }
    }, [equipmentPositionData])

    useEffect(() => {
        dispatch(updateEquipmentRoute(scheduleData?.slot.currentSlot?.geometry))
    }, [scheduleData])

    useEffect(() => {
        if (!showUnbindErrorDialog && scheduleError && 'status' in scheduleError) {
            if (scheduleError.status == 451 || scheduleError.status == 423) {
                setShowUnbindErrorDialog(true)
            }
        }
    }, [scheduleError])

    return <div>
        <SsmMap />

        <Button
            label="Меню"
            view="ghost"
            iconLeft={IconHamburger}
            onlyIcon
            onClick={() => setIsProfileOpen(true)}
            style={{
                position: "absolute",
                left: "10px",
                top: "10px",
                backgroundColor: "#ffffff",
                touchAction: 'none',
            }}
        />

        <ProfileSideBar
            isOpen={isProfileOpen}
            onClickOutside={() => setIsProfileOpen(false)}
            onLogout={async () => {
                await logout()
                await keycloak?.logout()
            }}
            additional={
                <Space direction="vertical" size="s">
                    <RegisterPlate regNumber={scheduleData?.slot.registrationPlateNumber ?? 'г/н не найден'} />
                    <EquipmentStateLabel state={scheduleData?.slot.resourceState} />
                </Space>
            }
            actions={
                [
                    <Button
                        key='end_shift_btn'
                        size="m"
                        view="clear"
                        label="Завершить смену"
                        iconLeft={IconStop}
                        onClick={() => {
                            endWork(undefined)
                        }}
                    />,
                    <Divider key="div_1" />,
                ]
            }
        />

        {scheduleData && !scheduleData.slot.shiftStarted && <div
            style={{
                position: "absolute",
                left: "10px",
                right: "10px",
                bottom: "40px",
                touchAction: 'none',
            }}
        >
            <SliderButton
                text='Начать смену'
                color='#0078d2'
                loading={(startWorkIsLoading || scheduleIsLoading) && scheduleData}
                onSuccess={
                    () => {
                        startWork(undefined);
                    }
                }
            />
        </div>}

        {scheduleData?.slot.shiftStarted && <Sheet
            style={{ zIndex: 5 }}
            ref={sceduleListRef}
            isOpen={true}
            onClose={() => { sceduleListRef.current?.snapTo(1) }}
            snapPoints={[window.innerHeight - 65, defaultHeaderSize]}
            detent="full-height"
            initialSnap={0}
        >
            <Sheet.Container>
                <Sheet.Header>
                    <Theme preset={presetGpnDefault}>
                        <BottomSheetDefaultHeader
                            onClose={selectedRequest
                                ? () => {
                                    dispatch(updateSelectedRequest(undefined))
                                    sceduleListRef.current?.snapTo(0)
                                }
                                : undefined}
                        />

                        {!selectedRequest && <ScheduleHeader
                            refetch={() => {
                                refetchSchedule()
                                refetchNotifications()
                                refetchEquipmentPosition()
                            }}
                            isFetching={scheduleisFetching && !scheduleIsLoading} />
                        }
                    </Theme>
                </Sheet.Header>
                <Sheet.Content>
                    <Sheet.Scroller draggableAt="both" id="scheduleBottomSheet">
                        <Theme preset={presetGpnDefault}>
                            <div>
                                {!selectedRequest && !scheduleError && <DriverPassengerState />}

                                {selectedRequest && !scheduleError && <PassengerRequestDetails request={selectedRequest} />}

                                {scheduleIsLoading && <ScheduleSkeleton />}

                                {!scheduleIsLoading && scheduleError && <ErrorDataPlaceholder
                                    title="Произошла ошибка"
                                    description="Не удалось загрузить список заданий"
                                />}

                                {(!scheduleIsLoading && scheduleData && (!scheduleData.schedule.requests || scheduleData.schedule.requests.length === 0))
                                    && <EmptyDataPlaceholder
                                        title="Пусто"
                                        description="У вас нет назначенных заданий или все задания выполнены"
                                    />}

                                {(!scheduleIsLoading && !scheduleError && scheduleData && scheduleData.schedule.requests && scheduleData.schedule.requests.length > 0)
                                    && <SchedulePassenger
                                        data={scheduleData}
                                        hidden={!!selectedRequest}
                                    />}

                            </div>
                        </Theme>
                    </Sheet.Scroller>
                </Sheet.Content>
            </Sheet.Container>
        </Sheet>}

        <DriverDeviceUnbindDialog
            isOpen={showUnbindErrorDialog}
            title={scheduleError && 'data' in scheduleError ? scheduleError.data as string : 'Ошибка привязки устройства'}
        />
    </div>
}