import { RootState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { ProgressSpin } from "@consta/uikit/ProgressSpin";
import { Space } from "../../../../../components/Space";
import { EquipmentStateLabel } from "../../../../../components/EquipmentStateLabel";
import { RegisterPlate } from "../../../../../components/RegisterPlate";
import { Text } from "@consta/uikit/Text"
import { EquipmentRoute } from "../../../../../components/EquipmentRoute";
import { Button } from "@consta/uikit/Button";
import { ErrorDataPlaceholder } from "../../../../../components/ErrorDataPlaceholder";
import { updateSelectedEquipmentPosition } from "../../../../../components/Map/slice";
import { EquipmentState } from "../../../../../components/EquipmentStateLabel/types";
import { useGetEquipmentDetailsQuery, useReturnToServiceMutation } from "./services";
import { useEffect, useState } from "react";
import { NewMechanicOfflineRequest } from "../NewOfflineRequest";
import toast, { Toaster } from "react-hot-toast";


export const EquipmentDetails = () => {
    const dispatch = useDispatch()

    const equipment = useSelector((state: RootState) => state.mechanic.selectedEquipment)!
    const { data, error, isLoading, refetch } = useGetEquipmentDetailsQuery(equipment.equipmentId)
    const [returnToService, { isLoading: requestLoading, isError: returnToServiceIsError }] = useReturnToServiceMutation()
    const [newOfflineRequest, setNewOfflineRequest] = useState<boolean>(false)

    useEffect(() => {
        if (returnToServiceIsError) {
            toast.error('Произошла ошибка. Не удалось выпустить на линию')
        }
    }, [returnToServiceIsError])

    if (data?.position) {
        dispatch(updateSelectedEquipmentPosition(data.position))
    }

    if (error) {
        return <ErrorDataPlaceholder
            title="Произошла ошибка"
            description="Не удалось загрузить информацию о технике"
        />
    }

    return <>
        {newOfflineRequest && <NewMechanicOfflineRequest onClose={(update) => {
            if (update) {
                refetch()
            }
            setNewOfflineRequest(false);
        }} />}

        {(data && !isLoading && !newOfflineRequest) && <Space
            direction="vertical"
            size="s"
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
            }}
        >
            <div style={{ width: "fit-content" }}>
                <EquipmentStateLabel state={data.state} />
            </div>

            <Space size="xs">
                <RegisterPlate regNumber={data.registrationPlate} />
                <Text truncate view="primary" weight="regular">{data.name}</Text>
            </Space>

            <EquipmentRoute
                titleOf={data.titleOf}
                titleTo={data.titleTo}
            />

            {data.state !== EquipmentState.STATE_OUT_OF_SERVICE
                ? <Button
                    width="full"
                    form="round"
                    label="Снять с линии"
                    style={{
                        marginTop: 16,
                        backgroundColor: '#EB5757'
                    }}
                    onClick={() => {
                        setNewOfflineRequest(true);
                    }}
                /> : <Button
                    width="full"
                    loading={requestLoading}
                    form="round"
                    label="Выпустить на линию"
                    style={{
                        marginTop: 16,
                        backgroundColor: '#09B37B'
                    }}
                    onClick={async () => {
                        await returnToService({ resourceId: equipment.equipmentId });
                        refetch()
                    }}
                />}
        </Space>}

        {(!data || isLoading) && <div style={{ textAlign: 'center', marginTop: 10 }}><ProgressSpin size="xl" /></div>}
    </>
}