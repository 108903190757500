import type { ErrorInfo, ReactElement } from 'react';
import React from 'react';
import { Text } from '@consta/uikit/Text';
import { useRouteError } from 'react-router';
import { Space } from '../Space';
import { Button } from '@consta/uikit/Button';


class ErrorBoundary extends React.Component<{ children: ReactElement }, { hasError: boolean; errorInfo: string }> {
    state = { hasError: false, errorInfo: '' };

    static getDerivedStateFromError(error: Error) {
        console.log('call getDerivedStateFromError')
        return { hasError: true, errorInfo: error.message };
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        // Sentry.captureException(error);
        // eslint-disable-next-line no-console
        console.log('call componentDidCatch')
        console.log(error, errorInfo);
    }

    render() {
        console.log('call render')
        console.log(this.state)
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Text view="alert">Something went wrong {this.state.errorInfo}</Text>
        }
        return this.props.children;
    }
}

function ErrorBoundaryV2() {
    let error = useRouteError();
    console.error(error);
    return <Space
        direction='vertical'
        style={{
            textAlign: 'center',
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)"
        }}
    >
        <Text size='l' weight='semibold' view="alert">Произошла ошибка</Text>
        <Text view="secondary">{String(error)}</Text>
        <Button
            size="m"
            view="ghost"
            label="Повторить"
            width="default"
            onClick={async () => {
                window.location.reload();
            }}
        />
    </Space>
}

export { ErrorBoundary, ErrorBoundaryV2 };
