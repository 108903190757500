import { FC } from "react"
import { BindResorcesRequest } from "../types"
import { Space } from "../../../../../components/Space"
import { RegisterPlate } from "../../../../../components/RegisterPlate"
import { Checkbox } from '@consta/uikit/Checkbox'
import { Button } from '@consta/uikit/Button'
import { IconCancel } from '@consta/icons/IconCancel'


interface Props {
    item: BindResorcesRequest
    checked: boolean
    onSelect: () => void
    onReject: () => void
}

export const RequestItem: FC<Props> = ({ item, onSelect, onReject, checked }) => {
    return <div
        style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
        }}>
        <Space spaceBetween>
            <Space>
                <Checkbox label={item.requesterName} checked={checked} onChange={onSelect} />
                <RegisterPlate regNumber={item.registrationPlateNumber} />
            </Space>
            <Button label="Отмена" view="ghost" iconLeft={IconCancel} onlyIcon onClick={onReject} />
        </Space>
    </div>
}