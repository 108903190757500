import { FC, ReactElement } from "react"
import { Space } from "./Space"
import { Text } from "@consta/uikit/Text"
import { IconBackward } from '@consta/icons/IconBackward';
import { Button } from "@consta/uikit/Button";
import { Divider } from "./Divider";


interface Props {
    title: string,
    onBackPress: () => void
    style?: React.CSSProperties
    actionBtn?: ReactElement
    tabs?: React.ReactNode
}

export const AppBar: FC<Props> = ({ title, onBackPress, style, tabs, actionBtn }) => {
    return <div
        style={
            {
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                backgroundColor: 'white',
                ...style
            }
        }>
        <Space
            spaceBetween
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
                paddingTop: 'var(--space-m)',
            }}
        >
            <Space>
                <Button
                    label="Назад"
                    view="ghost"
                    size="s"
                    iconLeft={IconBackward}
                    onlyIcon
                    onClick={onBackPress} />
                <Text
                    view="primary"
                    size="xl"
                    weight="semibold"
                >
                    {title}
                </Text>
            </Space>
            {!actionBtn ? <></> : actionBtn}
        </Space>

        {tabs && <div style={{ marginTop: 16 }}>{tabs}</div>}
        {!tabs && <Divider style={{ marginTop: 16 }} />}
    </div>

}