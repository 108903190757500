import ruLocale from 'date-fns/locale/ru';
import { format } from 'date-fns';
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { IconCalendar } from '@consta/icons/IconCalendar';
import { TextField } from "@consta/uikit/TextField";
import { DateTimeDialog } from "./DateTimeDialog";

export const SelectDateTime = ({
  disabled,
  label,
  name,
  rules,
  defaultValue,
  control,
  shouldUnregister,
  style,
  ...fieldProps
}: any) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const context = useFormContext();

  const renderSelectDateTime = ({ field, fieldState, formState }: any) => {
    const { onChange, onBlur, value, ref } = field;
    const handleChange = ({ e }: any) => {
      onChange(e);
    };
    return (

      <div>
        <TextField
          disabled={disabled}
          style={{ width: '100%' }}
          {...fieldProps}
          onChange={handleChange}
          onBlur={onBlur}
          ref={ref}
          placeholder="Выберите дату"
          label={label}
          readOnly
          required={rules?.required}
          rightSide={IconCalendar}
          value={value ? format(value!, "dd.MM.yyyy / HH:mm", { locale: ruLocale }) : ''}
          onClick={() => setIsDialogOpen(true)}
        />

        <DateTimeDialog
          style={style}
          isOpen={isDialogOpen}
          onClickOutside={() => setIsDialogOpen(false)}
          onSelect={(result) => {
            setIsDialogOpen(false)
            onChange(result)
          }}
        />
      </div>
    );
  };

  return <Controller
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    control={control || context.control}
    shouldUnregister={shouldUnregister}
    render={renderSelectDateTime}
  />
}