import { useLocation, useNavigate, useParams } from "react-router"
import toast, { Toaster } from 'react-hot-toast';
import { AppBar } from "../../../../components/AppBar";
import { Space } from "../../../../components/Space";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Divider } from "../../../../components/Divider";
import { Button } from "@consta/uikit/Button"
import { SelectMaterial } from "../../../../components/SelectMaterial";
import { SelectedMaterialvalue } from "../../../../components/SelectMaterial/types";
import { SelectDateTime } from "../../../../components/SelectDateTime";
import { SelectedDurationValue } from "../../../../components/SelectDuration/types";
import { SelectDuration } from "../../../../components/SelectDuration";
import { SelectEquipmentType } from "../../../../components/SelectEquipmentType";
import { SelectedEquimpentTypeCount } from "../../../../components/SelectEquipmentType/types";
import { SelectShuttle } from "../components/SelectShuttle";
import { TextField } from "@consta/uikit/TextField";
import { ShuttleValue } from "../components/SelectShuttle/types";
import { SelectShuttleType } from "../components/SelectShuttleType";
import { ShuttleTypeValue } from "../components/SelectShuttleType/types";
import { useGetWorkPlanQuery } from "../../services";
import { useCreateMaterialRequestMutation, useGetMaterialRequestDetailsQuery, useUpdateMaterialRequestMutation } from "./service";
import { useEffect, useState } from "react";
import { WorkPlaceValue } from "../MasterJobRequest/types";
import { SelectWorkPlace } from "../../../../components/SelectWorkPlace";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { RequestDetailsSkeleton } from "../components/RequestDetailsSkeleton";
import { addHours, isAfter, isBefore } from "date-fns";
import { MasterRequestState } from "../../home/types";


type FormData = {
    workPlace: WorkPlaceValue
    materials: SelectedMaterialvalue[]
    dateTimeOf: Date
    dateTimeTo: Date
    durationLoad: SelectedDurationValue
    equipment: SelectedEquimpentTypeCount[]
    shuttle: ShuttleValue
    shuttleType: ShuttleTypeValue
    maxResourceCount: number
    durationUnload: SelectedDurationValue
}

export const MasterMaterialRequest = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { requestId } = useParams();

    const isCopyMode = location.pathname.includes('copy')
    const isEditMode = location.pathname.includes('edit') && requestId

    const { data: detailsData, error: detailsError, isLoading: detailsIsLoading } = useGetMaterialRequestDetailsQuery(requestId, { skip: !requestId })
    const [sendRequest, { isLoading: sendIsLoading, isError: sendIsError, isSuccess: sendIsSuccess }] = useCreateMaterialRequestMutation()
    const [updateRequest, { isLoading: updateIsLoading, isError: updateIsError, isSuccess: updateIsSuccess }] = useUpdateMaterialRequestMutation()
    const { refetch: refetchWorkSchedule } = useGetWorkPlanQuery(undefined, { skip: !sendIsSuccess && !updateIsSuccess })

    const [editDisabled, setEditDisabled] = useState<boolean>(false)
    
    const teamId = useSelector((state: RootState) => state.master.teamId)

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isDirty, isValid, errors },
    } = useForm<FormData>({
        defaultValues: {
            materials: [
                {
                    material: undefined,
                    count: 1
                }
            ],
            durationLoad: {
                drurationHours: 0,
                drurationMinutes: 0,
            },
            durationUnload: {
                drurationHours: 0,
                drurationMinutes: 0,
            },
            shuttle: ShuttleValue.SINGLE,
            shuttleType: ShuttleTypeValue.EARLIER,
        }
    })

    const shuttle = watch('shuttle')
    const dateTimeTo = watch('dateTimeTo')

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const requestData =  {
            state: detailsData?.state ? detailsData?.state : MasterRequestState.CREATED,
            isSignificantChanges: false,
            name: `Завоз метериалов на ${data.workPlace.oilField.title}`,
            title: `Завоз метериалов на ${data.workPlace.oilField.title}`,
            teamId: teamId,
            schedule: {
                id: detailsData?.sheduleId,
                name: `Завоз метериалов на ${data.workPlace.oilField.title}`,
                title: `Завоз метериалов на ${data.workPlace.oilField.title}`,
                dateTimeOf: data.dateTimeOf.toISOString(),
                dateTimeTo: data.dateTimeTo.toISOString(),
                isShuttle: shuttle == ShuttleValue.SHUTTLE,
                equipmentTypeParams: data.equipment.map((element) => ({
                    equipmentTypeId: element.equipmentType.id,
                    countType: 'MIN',
                    count: element.count
                })),
                materialRequirements: data.materials.map((element) => ({
                    materialId: element.material.id,
                    qty: element.count
                })),
                loadingDuration: Number(data.durationLoad.drurationMinutes) + Number(data.durationLoad.drurationHours * 60),
                priority: 50,
                poiId: data.workPlace.cluster.id,
                wellId: data.workPlace?.well?.id,
                location: data.workPlace.cluster.id,
                uniformDistribution: data.shuttleType == ShuttleTypeValue.EVENLY,
                maxResourceCount: data.maxResourceCount,
                unloadingDuration: Number(data.durationUnload?.drurationMinutes) + Number(data.durationUnload?.drurationHours * 60),

            }
        }

        if (isEditMode) {
            await updateRequest({
                id: requestId,
                body: requestData,
            })
        } else {
            await sendRequest(requestData)
        }   
    }

    useEffect(() => {
        if (sendIsError || updateIsError) {
            toast.error('Произошла ошибка. Не удалось создать заявку')
        }
        if (sendIsSuccess || updateIsSuccess) {
            refetchWorkSchedule()
            navigate(-1)
        }
    }, [sendIsError, updateIsError, sendIsSuccess, updateIsSuccess])

    useEffect(() => {
        if (detailsData) {
            if (isCopyMode) {
                setValue("dateTimeOf", addHours(detailsData.dateTimeOf, 24))
                setValue("dateTimeTo", addHours(detailsData.dateTimeTo, 24))
            } else {
                setValue("dateTimeOf", detailsData.dateTimeOf)
                setValue("dateTimeTo", detailsData.dateTimeTo)
            }
            setValue("materials", detailsData.materials)
            setValue("equipment", detailsData.equipment)
            setValue("durationLoad", detailsData.durationLoad)
            setValue("durationUnload", detailsData.durationUnload)
            setValue("shuttle", detailsData.shuttle)
            setValue("maxResourceCount", detailsData.maxResourceCount)
            setValue("shuttleType", detailsData.shuttleType)
            setValue("workPlace", detailsData.workPlace)

            const disabled =
                detailsData.hasFactSlots ||
                isAfter(new Date(), detailsData.dateTimeTo) ||
                [
                    MasterRequestState.IN_PROGRESS,
                    MasterRequestState.COMPLETED,
                    MasterRequestState.PART_COMPLETED,
                ].includes(detailsData.state)
            setEditDisabled(disabled)
        }
    }, [detailsData])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingBottom: 80 }}>
            <AppBar
                style={{ zIndex: 2 }}
                title="Заявка на материалы"
                onBackPress={() => navigate(-1)}
            />

            {detailsIsLoading && !detailsError && <RequestDetailsSkeleton />}

            {!detailsIsLoading && !detailsError && <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}>

                <SelectWorkPlace
                    disabled={editDisabled}
                    level={3}
                    style={{ zIndex: 10 }}
                    name='workPlace'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectMaterial
                    disabled={editDisabled}
                    title='Потребность в материалах'
                    name='materials'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Начать разгрузку в'
                    style={{ zIndex: 10 }}
                    name='dateTimeOf'
                    control={control}
                    rules={{ required: true, validate: (field: Date) => isBefore(field, dateTimeTo) }}
                />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Закончить разгрузку до'
                    style={{ zIndex: 10 }}
                    name='dateTimeTo'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectDuration
                    disabled={editDisabled}
                    usePresets={false}
                    label='Длительность погрузки'
                    style={{ zIndex: 10 }}
                    name='durationLoad'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectEquipmentType
                    disabled={editDisabled}
                    title='Техника для разгрузки'
                    style={{ zIndex: 10 }}
                    name='equipment'
                    control={control}
                    rules={{ required: false }}
                />

                <Divider />

                <SelectShuttle
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='shuttle'
                    control={control}
                    rules={{ required: true }}
                />

                {shuttle == ShuttleValue.SHUTTLE ? <SelectShuttleType
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='shuttleType'
                    control={control}
                    rules={{ required: shuttle == ShuttleValue.SHUTTLE }}
                /> : <></>}

                {shuttle == ShuttleValue.SHUTTLE ? <Controller
                    name="maxResourceCount"
                    control={control}
                    rules={{ required: shuttle == ShuttleValue.SHUTTLE }}
                    render={({ field }) => <TextField
                        disabled={editDisabled}
                        value={field.value?.toString()}
                        required
                        onBlur={field.onBlur}
                        onChange={(event) => {
                            field.onChange(event.value)
                        }}
                        label="Количество техники (макс.)"
                        type="number"
                        incrementButtons={false}
                        min={1}
                    />}
                /> : <></>}

                {shuttle == ShuttleValue.SHUTTLE ? <SelectDuration
                    disabled={editDisabled}
                    usePresets={false}
                    label='Длительность разгрузки'
                    style={{ zIndex: 10 }}
                    name='durationUnload'
                    control={control}
                    rules={{ required: true }}
                /> : <></>}

            </Space>}
        </div>

        <div
            style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                left: 0,
                bottom: 0,
                width: '100%',
                height: '60px',
            }}
        >
            <Divider />
            <div
                style={{
                    paddingTop: 6,
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Button
                    view={isEditMode ? 'secondary' : 'primary'}
                    form="round"
                    label={isEditMode ? 'Сохранить' : 'Создать'} 
                    width="full"
                    disabled={(!requestId && !isDirty) || !isValid}
                    loading={sendIsLoading || updateIsLoading}
                    type="submit"
                />
            </div>
        </div>
    </form>
}